import { formatDistanceToNowStrict, intervalToDuration } from "date-fns";
import { ApplicationStatusEnum } from "~/bff/graphql/generated/graphql";

export const getApplicationStatusTagColor = (
  applicationStatus?: ApplicationStatusEnum,
) => {
  switch (applicationStatus) {
    case ApplicationStatusEnum.Prospect:
    case ApplicationStatusEnum.InterviewNotScheduled:
    case ApplicationStatusEnum.NeedsReview:
      return "soft-orange";
    case ApplicationStatusEnum.InvitedToApply:
    case ApplicationStatusEnum.InterviewCompleted:
      return "leaf-green";
    case ApplicationStatusEnum.InviteDeclined:
    case ApplicationStatusEnum.Rejected:
      return "cherry-red";
    case ApplicationStatusEnum.InterviewRequested:
      return "neon-yellow";
    case ApplicationStatusEnum.InterviewResend:
      return "yolk-yellow";
    case ApplicationStatusEnum.InterviewScheduled:
      return "moss-green";
    default:
      return undefined;
  }
};

export const formatStatusTime = (lastUpdatedStatusAt?: string) => {
  if (!lastUpdatedStatusAt) return "";

  const { years, months, days, hours, minutes } = intervalToDuration({
    start: new Date(lastUpdatedStatusAt),
    end: new Date(),
  });

  const units = [
    { value: years, label: "Y" },
    { value: months, label: "M" },
    { value: days, label: "d" },
    { value: hours, label: "h" },
    { value: minutes, label: "m" },
  ];

  for (const { value, label } of units) {
    if (value) {
      if (label === "d" && value >= 7) {
        return `${Math.floor(value / 7)}W ago`;
      }

      return `${value}${label} ago`;
    }
  }

  return "Just now";
};
