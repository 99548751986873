import React from "react";
import { Title, TitleSize } from "~/scalis-components/core/typography";
import { RadioGroup } from "~/scalis-components/core/radio-group/default/radio-group";
import { ScheduleRadioProps } from "./schedule-radio.types";
import {
  FIELD_NAME_SCHEDULE_RADIO,
  LABEL_SCHEDULE_RADIO,
  SCHEDULE_OPTIONS_LABELS,
  ScheduleOptionEnum,
} from "./schedule-radio.constants";
import { RadioGroupType } from "~/scalis-components/core/radio-group/default/radio-group.types";

export const SCHEDULE_OPTIONS = [
  {
    label: SCHEDULE_OPTIONS_LABELS.SCHEDULE_LATER,
    value: ScheduleOptionEnum.ScheduleLater.toString(),
  },
  {
    label: SCHEDULE_OPTIONS_LABELS.REQUEST_AVAILABILITY,
    value: ScheduleOptionEnum.RequestAvailability.toString(),
  },
  {
    label: SCHEDULE_OPTIONS_LABELS.SEND_SCHEDULER_LINK,
    value: ScheduleOptionEnum.SendSchedulerLink.toString(),
  },
];

export const ScheduleRadio: React.FC<ScheduleRadioProps> = ({
  onValueChange,
  value,
}) => {
  const handleValueChange = (value: string) => {
    onValueChange?.(FIELD_NAME_SCHEDULE_RADIO, value);
  };

  return (
    <div className="flex flex-col gap-4">
      <Title size={TitleSize.extraSmall}>{LABEL_SCHEDULE_RADIO}</Title>
      <RadioGroup
        name={FIELD_NAME_SCHEDULE_RADIO}
        options={SCHEDULE_OPTIONS}
        value={value}
        onValueChange={handleValueChange}
        optionsContainerClassName="flex flex-col gap-2"
        type={RadioGroupType.Highlited}
      />
    </div>
  );
};
