"use client";

import { useRef, useState } from "react";
import { TriangleRightIcon } from "@radix-ui/react-icons";
import { cn } from "~/utils/cn";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/components/v2/Collapsible";
import { EmptyState } from "~/scalis-components/core";
import { LoadingState } from "~/scalis-components/team";
import { CollapsibleListViewProps } from "./collapsible-list-view.types";
import { CandidateListView } from "../candidate-list-view";
import { CandidateListViewTitle } from "~/scalis-components/pipeline/list-view/candidate-list-view-title";

export const CollapsibleListView: React.FC<CollapsibleListViewProps> = ({
  listView,
  title,
  squareColor,
  handleClickTitle,
  id,
  open = false,
  candidates,
  isLoading,
  showSeeAllCandidates,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="justify-star flex">
      <Collapsible className="w-full" open={isOpen} onOpenChange={setIsOpen}>
        <div className="flex">
          <CollapsibleTrigger className="group" asChild>
            <div className="flex h-8 cursor-pointer items-center" role="button">
              <TriangleRightIcon className="h-5 w-5 rounded-md text-icons-low-contrast hover:bg-primary-background active:bg-primary-background-hover active:text-typography-color group-data-[state=open]:rotate-90" />
            </div>
          </CollapsibleTrigger>
          <CandidateListViewTitle
            squareColor={squareColor}
            title={title}
            action={handleClickTitle}
            stageId={Number(id)}
          />
        </div>

        <CollapsibleContent
          className={cn("mt-5 overflow-hidden", {
            "list-view-container overflow-x-auto":
              !!candidates.length && !isLoading,
          })}
          ref={containerRef}
        >
          <div className="pb-4">
            {(!candidates.length || isLoading) && (
              <div className="rounded-xl border border-solid border-borders-borders p-8">
                {isLoading && <LoadingState />}

                {!isLoading && (
                  <EmptyState
                    title="No candidates found!"
                    message="We're sorry, but it seems like there are no candidates for you to review at this stage."
                    icon="fa-regular fa-magnifying-glass"
                    iconSize="fa-3x"
                  />
                )}
              </div>
            )}

            {!isLoading && !!candidates.length && (
              <CandidateListView
                {...listView}
                showSeeAllCandidates={showSeeAllCandidates}
                rows={candidates}
                id={id}
                ref={containerRef}
              />
            )}
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};
