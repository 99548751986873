import { SourceCategoryEnum, SourceNameEnum } from "~/bff/graphql/generated/graphql";

export const POPUP_TYPE_UPLOAD_CANDIDATE_DRAWER = "upload_candidate_drawer";

export const FORM_ID = "candidate-profile-form";

export const FIELD_NAME_JOB_LISTING = "jobListing";
export const FIELD_NAME_FIRST_NAME = "firstName";
export const FIELD_NAME_LAST_NAME = "lastName";
export const FIELD_NAME_LINKEDIN = "linkedIn";
export const FIELD_NAME_LINKEDIN_URL = "linkedInUrl";
export const FIELD_NAME_EMAIL = "email";
export const FIELD_NAME_EMAIL_ADDRESS = "emailAddress";
export const FIELD_NAME_PHONE_NUMBER = "phoneNumber";
export const FIELD_NAME_SOURCE_CATEGORY = "sourceCategory";
export const FIELD_NAME_SOURCE_NAME = "sourceName";
export const FIELD_NAME_WHO_GETS_CREDIT = "whoGetsCredit";
export const FIELD_NAME_NOTES = "notes";
export const FIELD_NAME_DOCUMENTS = "documents";
export const FIELD_NAME_CONTACT_METHOD = "contactMethod";

export const sourceCategoryOptions = [
  {
    label: "Sourced",
    value: SourceCategoryEnum.Sourced,
  },
  {
    label: "Direct Applicant",
    value: SourceCategoryEnum.DirectApplicant,
  },
  {
    label: "Agency",
    value: SourceCategoryEnum.Agency,
  },
  {
    label: "Referred",
    value: SourceCategoryEnum.Referred,
  },
  {
    label: "University",
    value: SourceCategoryEnum.University,
  },
  {
    label: "Internal",
    value: SourceCategoryEnum.Internal,
  },
];

export const sourceNameOptions = [
  {
    label: "Careers Page Or Embed",
    value: SourceNameEnum.CareersPageOrEmbed,
  },
  {
    label: "Custom",
    value: SourceNameEnum.Custom,
  },
  {
    label: "Indeed",
    value: SourceNameEnum.Indeed,
  },
  {
    label: "Linkedin",
    value: SourceNameEnum.Linkedin,
  },
  {
    label: "Organic Traffic",
    value: SourceNameEnum.OrganicTraffic,
  },
  {
    label: "Past Candidates",
    value: SourceNameEnum.PastCandidates,
  },
  {
    label: "Scalis Job Board",
    value: SourceNameEnum.ScalisJobBoard,
  },
  {
    label: "Scalis Match",
    value: SourceNameEnum.ScalisMatch,
  },
  {
    label: "Zip Recruiter",
    value: SourceNameEnum.Ziprecruiter,
  },
];

export const FORM_FIELDS = {
  [FIELD_NAME_JOB_LISTING]: {
    name: FIELD_NAME_JOB_LISTING,
    label: "Job Listing",
    emptyTerm: "an option",
  },
  [FIELD_NAME_FIRST_NAME]: {
    name: FIELD_NAME_FIRST_NAME,
    label: "First Name",
    placeholder: "Enter first name here",
  },
  [FIELD_NAME_LAST_NAME]: {
    name: FIELD_NAME_LAST_NAME,
    label: "Last Name",
    placeholder: "Enter last name here",
  },
  [FIELD_NAME_LINKEDIN]: {
    name: FIELD_NAME_LINKEDIN,
    id: "linkedin-checkbox",
    label: "LinkedIn",
  },
  [FIELD_NAME_EMAIL]: {
    name: FIELD_NAME_EMAIL,
    id: "email-checkbox",
    label: "Email",
  },
  [FIELD_NAME_LINKEDIN_URL]: {
    name: FIELD_NAME_LINKEDIN_URL,
    label: "LinkedIn URL",
    placeholder: "Enter LinkedIn URL here",
  },
  [FIELD_NAME_EMAIL_ADDRESS]: {
    name: FIELD_NAME_EMAIL_ADDRESS,
    label: "Email Address",
    placeholder: "Enter email address here",
  },
  [FIELD_NAME_PHONE_NUMBER]: {
    name: FIELD_NAME_PHONE_NUMBER,
    label: "Phone Number (Optional)",
    placeholder: "000-0000",
  },
  [FIELD_NAME_SOURCE_CATEGORY]: {
    name: FIELD_NAME_SOURCE_CATEGORY,
    label: "Source category",
    emptyTerm: "an option",
    options: sourceCategoryOptions,
  },
  [FIELD_NAME_SOURCE_NAME]: {
    name: FIELD_NAME_SOURCE_NAME,
    label: "Source name",
    emptyTerm: "an option",
    options: sourceNameOptions,
  },
  [FIELD_NAME_WHO_GETS_CREDIT]: {
    name: FIELD_NAME_WHO_GETS_CREDIT,
    label: "Who gets credit (Optional)",
    emptyTerm: "an option",
  },
  [FIELD_NAME_NOTES]: {
    name: FIELD_NAME_NOTES,
    placeholder: "Insert here your notes about the candidate"
  },
};
