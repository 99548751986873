import { Prisma, uploadedCandidate } from "@prisma/client";
import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

/** @deprecated */
export const SourceMap = {
  Sourced: ["Past Candidates", "SCALIS Match"],
  "Direct Applicant": [
    "Careers Embed",
    "SCALIS Job Board",
    "LinkedIn",
    "ZipRecruiter",
    "Indeed",
    "Organic Traffic",
  ],
  Agency: [],
  Referred: [],
  University: [],
  Internal: [],
};

const sourceCategories = Object.keys(SourceMap) as [string, ...string[]];
const sources = ["Other", ...Object.values(SourceMap).flat()] as [
  string,
  ...string[],
];

/** @deprecated */
export enum DetailsEntryTypeEnum {
  Email = "email-v1",
  Phone = "phone-v1",
  Location = "location-v1",
  Profile = "profile-v1",
  Source = "source-v1",
  MedalistDesignation = "medalist_designation-v1",
  Timezone = "timezone-v1",
}

/** @deprecated */
export const EmailEntrySchema = z.object({
  type: z.literal(DetailsEntryTypeEnum.Email),
  email: z.string().email(),
});

/** @deprecated */
export const PhoneEntrySchema = z.object({
  type: z.literal(DetailsEntryTypeEnum.Phone),
  phone: z.custom<string>(
    value => typeof value === "string" && isValidPhoneNumber(value),
    "Invalid phone number",
  ),
});

/** @deprecated */
export const LocationEntrySchema = z.object({
  type: z.literal(DetailsEntryTypeEnum.Location),
  city: z.string().min(1, "Invalid city"),
  state: z.string().min(1, "Invalid state"),
  postalCode: z.string().optional(),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
});

/** @deprecated */
export const ProfileEntrySchema = z.object({
  type: z.literal(DetailsEntryTypeEnum.Profile),
  link: z.string().url(),
});

/** @deprecated */
export const SourceEntrySchema = z.object({
  type: z.literal(DetailsEntryTypeEnum.Source),
  sourceCategory: z.enum(sourceCategories, {
    required_error: "Invalid source category",
    invalid_type_error: "Invalid source category",
  }),
  source: z.enum(sources, {
    required_error: "Invalid source",
    invalid_type_error: "Invalid source",
  }),
  credit: z.string().optional(),
});

/** @deprecated */
export const MedalistDesignationEntrySchema = z.object({
  type: z.literal(DetailsEntryTypeEnum.MedalistDesignation),
  medal: z.string(),
  job: z.string(),
});

/** @deprecated */
export const TimezoneEntrySchema = z.object({
  type: z.literal(DetailsEntryTypeEnum.Timezone),
  timezone: z.string(),
});

/** @deprecated */
export const DetailsEntrySchema = z.union([
  EmailEntrySchema,
  PhoneEntrySchema,
  LocationEntrySchema,
  ProfileEntrySchema,
  SourceEntrySchema,
  MedalistDesignationEntrySchema,
  TimezoneEntrySchema,
]);

/** @deprecated */
export const DetailsSchema = z.array(DetailsEntrySchema);

/** @deprecated */
export type DetailsEntry = z.infer<typeof DetailsEntrySchema>;

/** @deprecated */
export type DetailsEntryType = DetailsEntry["type"];

/** @deprecated */
export type Candidate = {
  fullName: string;
  email: string;
  phone: string;
  currentEducation: string;
  currentJob: string;
} & (
  | ({ hasSCALISProfile: true } & Prisma.JobSeekerAccountGetPayload<{
      include: {
        job_seeker_basicinfo: true;
        seeker_profile_header: true;
        educations: true;
        jobExpericences: true;
      };
    }>)
  | ({ hasSCALISProfile: false } & uploadedCandidate)
);
