import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import {
  FaCheck,
  FaChevronDown,
  FaChevronUp,
  FaLink,
  FaRegCalendar,
  FaXmark,
} from "react-icons/fa6";

import { Button } from "~/components/v2/Button";
import { StagePill } from "~/components/v2/stage-pill";
import { useGetApplicationsByJobId } from "~/hooks/applications/useGetApplicationsByJobId";
import { useUserWithNylasAccount } from "~/hooks/calendar/useUserWithNylasAccount";
import {
  useCompleteInterview,
  useDeleteInterview,
} from "~/hooks/interviews/useScheduleInterviews";
import {
  useDeleteSchedulerInvite,
  useResendSchedulerInvite,
} from "~/hooks/scheduler/useSchedulerInvite";
import { useMockedSession } from "~/hooks/useMockedSession";
import { useToast } from "~/hooks/useToast";
import { Application } from "~/scalis-components/pipeline/detailed-candidate-view";
import {
  InterviewCandidate,
  InterviewEvent,
  InterviewEventWithStage,
  ScheduleInterviewDrawer,
  ScheduleInterviewProvider,
  SchedulerLinkInvite,
  StageWithInterview,
} from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview";
import { EditInterviewDrawer } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer";
import { InterviewEventCard } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/interview-event-card";
import { InterviewTypeSelectionDrawer } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/interview-type-selection-drawer";
import { ScheduleTypeEnum } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/interview-type-selection-drawer/interview-type-selection-drawer.types";
import { SchedulerLinkDrawer } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/scheduler-link-drawer/scheduler-link-drawer";
import { mapCandidatesDataToCandidates } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/reject-candidates/reject-candidate-drawer/reject-candidate-drawer.utils";
import { cn } from "~/utils/cn";
import { errorHandler } from "~/utils/error";

type StagePanelProps = {
  data: Application;
  stage: StageWithInterview;
  userId: number;
};

const RenderButton = ({
  className = "gap-3 px-3 mx-0",
  isLoading,
  disabled,
  onClick,
  children,
  icon: Icon,
}: {
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  icon: React.ElementType;
}) => (
  <Button
    variant="tonal"
    className={className}
    isLoading={isLoading}
    disabled={disabled}
    onClick={onClick}
  >
    <span>{children}</span>
    <Icon className="text-base" />
  </Button>
);

const ActionsButtonSet = ({
  user,
  interview,
  schedulerLinkInvite,
  status,
  canCancel,
  isDeleteInterviewLoading,
  handleCancelInterview,
  isDeleteSchedulerLinkInviteLoading,
  handleCancelSchedulerLinkInvite,
  isResendSchedulerLinkInviteLoading,
  handleResendSchedulerLinkInvite,
  setOpenEditInterviewDrawer,
  setOpenInterviewTypeDrawer,
}: {
  user: any;
  interview?: InterviewEvent;
  schedulerLinkInvite?: SchedulerLinkInvite;
  status: string;
  canCancel: boolean;
  isDeleteInterviewLoading: boolean;
  handleCancelInterview: (id: number) => void;
  isDeleteSchedulerLinkInviteLoading: boolean;
  handleCancelSchedulerLinkInvite: (id: number) => void;
  isResendSchedulerLinkInviteLoading: boolean;
  handleResendSchedulerLinkInvite: (id: number) => void;
  setOpenEditInterviewDrawer: (state: boolean) => void;
  setOpenInterviewTypeDrawer: (state: boolean) => void;
}) => {
  if (status === "Scheduled") {
    return canCancel ? (
      <>
        <RenderButton
          isLoading={isDeleteInterviewLoading}
          disabled={isDeleteInterviewLoading}
          onClick={() => handleCancelInterview(interview?.id!)}
          icon={FaXmark}
        >
          Cancel
        </RenderButton>
        <RenderButton
          onClick={() => setOpenEditInterviewDrawer(true)}
          icon={FaRegCalendar}
        >
          Edit
        </RenderButton>
      </>
    ) : (
      <RenderButton
        onClick={() => setOpenEditInterviewDrawer(true)}
        icon={FaRegCalendar}
      >
        View
      </RenderButton>
    );
  }

  if (status === "Interview Self-Schedule Link Sent") {
    return (
      <>
        <RenderButton
          isLoading={isDeleteSchedulerLinkInviteLoading}
          disabled={isDeleteSchedulerLinkInviteLoading}
          onClick={() =>
            handleCancelSchedulerLinkInvite(schedulerLinkInvite?.id!)
          }
          icon={FaXmark}
        >
          Cancel
        </RenderButton>
        <RenderButton
          isLoading={isResendSchedulerLinkInviteLoading}
          disabled={isResendSchedulerLinkInviteLoading}
          onClick={() =>
            handleResendSchedulerLinkInvite(schedulerLinkInvite?.id!)
          }
          icon={FaLink}
        >
          Resend link
        </RenderButton>
        <RenderButton
          disabled={!user?.nylas_account}
          onClick={() => setOpenInterviewTypeDrawer(true)}
          icon={FaRegCalendar}
        >
          Schedule
        </RenderButton>
      </>
    );
  }

  return (
    <RenderButton
      disabled={!user?.nylas_account}
      onClick={() => setOpenInterviewTypeDrawer(true)}
      icon={FaRegCalendar}
    >
      Schedule
    </RenderButton>
  );
};

export const StagePanel: React.FC<StagePanelProps> = ({
  data,
  stage,
  userId,
}) => {
  const { data: session } = useMockedSession();
  const { user } = useUserWithNylasAccount(session?.user?.email ?? "");
  const { toast } = useToast();

  const [hidden, setHidden] = useState(true);
  const [openInterviewTypeDrawer, setOpenInterviewTypeDrawer] = useState(false);
  const [openScheduleInterviewDrawer, setOpenScheduleInterviewDrawer] =
    useState(false);
  const [openEditInterviewDrawer, setOpenEditInterviewDrawer] = useState(false);
  const [openSchedulerLinkDrawer, setOpenSchedulerLinkDrawer] = useState(false);

  const queryClient = useQueryClient();

  const handleOnSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ["candidate-application", data.id],
    });
  };

  const handleOnError = (e: any) => {
    errorHandler(toast, e as AxiosError<any>);
  };

  const { data: candidatesData, isLoading: isLoadingCandidates } =
    useGetApplicationsByJobId(data.jobId);

  const { mutateAsync: deleteInterview, isPending: isDeleteInterviewLoading } =
    useDeleteInterview({
      onSuccess: handleOnSuccess,
      onError: handleOnError,
    });

  const {
    mutateAsync: deleteSchedulerLinkInvite,
    isPending: isDeleteSchedulerLinkInviteLoading,
  } = useDeleteSchedulerInvite({
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });

  const { mutateAsync: completeInterview, isPending: isCompleteLoading } =
    useCompleteInterview({
      onSuccess: handleOnSuccess,
      onError: handleOnError,
    });

  const {
    mutateAsync: resendSchedulerInvite,
    isPending: isResendSchedulerLinkInviteLoading,
  } = useResendSchedulerInvite({
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });

  const stages = useMemo(
    () =>
      data.stages?.filter(
        stageItem =>
          stageItem &&
          (stageItem.milestone.milestoneType === "PRE_SCREEN" ||
            stageItem.milestone.milestoneType === "CUSTOM"),
      ),
    [data.stages],
  );

  const interview = stage?.interview as InterviewEvent;
  const schedulerLinkInvite = stage?.schedulerLinkInvite as SchedulerLinkInvite;

  const status = useMemo(() => {
    if (interview)
      return interview?.status !== "INTERVIEW_COMPLETED"
        ? "Scheduled"
        : "Completed";
    if (
      schedulerLinkInvite &&
      schedulerLinkInvite.status === "INTERVIEW_REQUESTED"
    )
      return "Interview Self-Schedule Link Sent";
    return "Not Scheduled";
  }, [interview, schedulerLinkInvite]);

  const interviews = useMemo(
    () =>
      stages
        ?.filter(s => s?.interview)
        .map(s => ({
          ...(s?.interview as unknown as InterviewEvent),
          stage: s,
        })) ?? [],
    [stages],
  ) as unknown as InterviewEventWithStage[];

  const interviewers = useMemo(
    () =>
      data.interviewers?.filter(
        interviewer => interviewer.is_connected && interviewer?.email,
      ) ?? [],
    [data.interviewers],
  );

  const handleInterviewTypeSelect = useCallback(
    (type: ScheduleTypeEnum) => {
      setOpenInterviewTypeDrawer(false);

      if (type === ScheduleTypeEnum.MANUALLY_SCHEDULE) {
        setOpenScheduleInterviewDrawer(true);
      } else {
        setOpenSchedulerLinkDrawer(true);
      }
    },
    [
      setOpenInterviewTypeDrawer,
      setOpenScheduleInterviewDrawer,
      setOpenSchedulerLinkDrawer,
    ],
  );

  const handleCompleteInterview = useCallback(
    async (eventId: number) => {
      await completeInterview({
        eventId,
        data: { applicationId: data.id, stageId: stage.id },
      });
    },
    [completeInterview, data.id, stage.id],
  );

  const canCancel = useMemo(
    () =>
      user?.nylas_account?.nylas_calendars.some(
        c => c.calendar_id === interview?.nylasCalendar?.calendar_id,
      ),
    [user, interview],
  );

  const handleCancelInterview = useCallback(
    async (eventId: number) => {
      await deleteInterview({
        eventId,
        applicationId: data.id,
        stageId: stage.id,
      });
    },
    [deleteInterview, data.id, stage.id],
  );

  const handleCancelSchedulerLinkInvite = useCallback(
    async (schedulerLinkInviteId: number) => {
      await deleteSchedulerLinkInvite({ id: schedulerLinkInviteId });
    },
    [deleteSchedulerLinkInvite, data.id, stage.id],
  );

  const shouldShowCompleteButton = useMemo(() => {
    const isInterviewInProgress =
      stage?.interview && interview?.status !== "INTERVIEW_COMPLETED";
    return isInterviewInProgress;
  }, [stage, interview, schedulerLinkInvite]);

  const handleResendSchedulerLinkInvite = useCallback(
    async (schedulerLinkInviteId: number) => {
      await resendSchedulerInvite({ id: schedulerLinkInviteId });
    },
    [resendSchedulerInvite, data.id, stage.id],
  );

  const candidates = useMemo(
    () => mapCandidatesDataToCandidates(candidatesData || []),
    [candidatesData],
  ) as InterviewCandidate[];

  const candidate = {
    id: data.candidate.id,
    name: data.candidate.fullName,
    email: data.candidate.email || data.uploadedCandidate?.email,
    type: data.jobseeker_account?.role || "",
  } as unknown as InterviewCandidate;

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <StagePill stage={stage} />
            <span className="text-sm text-gray-500">
              {hidden ? status : ""}
            </span>
          </div>
          <div className="flex items-center gap-4">
            {shouldShowCompleteButton && (
              <RenderButton
                className="h-8 gap-2"
                isLoading={isCompleteLoading}
                disabled={isCompleteLoading}
                onClick={() => handleCompleteInterview(interview?.id!)}
                icon={FaCheck}
              >
                Mark complete
              </RenderButton>
            )}
            {hidden ? (
              <FaChevronDown
                className="cursor-pointer text-sm"
                onClick={() => setHidden(b => !b)}
              />
            ) : (
              <FaChevronUp
                className="cursor-pointer text-sm"
                onClick={() => setHidden(b => !b)}
              />
            )}
          </div>
        </div>
        <div className={cn("mt-2", { hidden })}>
          <div className="my-2 text-typography-high-contrast">
            <span className="text-sm font-semibold">Interview Status: </span>
            <span className="text-sm text-dark-color-text-100">{status}</span>
          </div>

          {interview && <InterviewEventCard interview={interview} />}

          <div className="mt-2 flex justify-end gap-3">
            <ActionsButtonSet
              user={user}
              interview={interview}
              schedulerLinkInvite={schedulerLinkInvite}
              status={status}
              canCancel={!!canCancel}
              isDeleteInterviewLoading={isDeleteInterviewLoading}
              handleCancelInterview={handleCancelInterview}
              isDeleteSchedulerLinkInviteLoading={
                isDeleteSchedulerLinkInviteLoading
              }
              handleCancelSchedulerLinkInvite={handleCancelSchedulerLinkInvite}
              isResendSchedulerLinkInviteLoading={
                isResendSchedulerLinkInviteLoading
              }
              handleResendSchedulerLinkInvite={handleResendSchedulerLinkInvite}
              setOpenEditInterviewDrawer={setOpenEditInterviewDrawer}
              setOpenInterviewTypeDrawer={setOpenInterviewTypeDrawer}
            />
          </div>
        </div>
      </div>
      <InterviewTypeSelectionDrawer
        isCalendarConnected={user?.nylas_account ? true : false}
        open={openInterviewTypeDrawer}
        onOpenChange={setOpenInterviewTypeDrawer}
        onSelect={handleInterviewTypeSelect}
      />
      {user?.nylas_account && (
        <>
          <ScheduleInterviewProvider
            applicationId={data.id}
            stageId={stage.id}
            calendars={user.nylas_account.nylas_calendars}
            candidates={candidates}
            interviewers={interviewers}
            defaultTimezone={user.nylas_account.timezone}
          >
            <SchedulerLinkDrawer
              candidate={candidate}
              open={openSchedulerLinkDrawer}
              onOpenChange={setOpenSchedulerLinkDrawer}
            />
            <ScheduleInterviewDrawer
              open={openScheduleInterviewDrawer}
              stages={stages?.filter(s => s?.interview === null) || []}
              onOpenChange={setOpenScheduleInterviewDrawer}
            />
            <EditInterviewDrawer
              open={openEditInterviewDrawer}
              userId={userId}
              initialInterviews={[...interviews]}
              stages={stages!}
              onOpenChange={setOpenEditInterviewDrawer}
            />
          </ScheduleInterviewProvider>
        </>
      )}
    </>
  );
};
