import React from "react";
import { Title, TitleSize } from "~/scalis-components/core/typography";
import { NotificationRadioProps } from "./notification-radio.types";
import { FIELD_NAME_NOTIFICATION_RADIO } from "./notification-radio.constants";
import { RadioGroup } from "~/scalis-components/core/radio-group/default/radio-group";
import { RadioGroupType } from "~/scalis-components/core/radio-group/default/radio-group.types";

export const NotificationRadio: React.FC<NotificationRadioProps> = ({
  label,
  value,
  onValueChange,
  notificationOptions,
}) => {
  const handleValueChange = (value: string) => {
    onValueChange?.(FIELD_NAME_NOTIFICATION_RADIO, value);
  };

  return (
    <div className="flex flex-col gap-4">
      {label && <Title size={TitleSize.extraSmall}>{label}</Title>}
      <RadioGroup
        name={FIELD_NAME_NOTIFICATION_RADIO}
        options={notificationOptions}
        value={value}
        onValueChange={handleValueChange}
        optionsContainerClassName="flex flex-col gap-2"
        type={RadioGroupType.Highlited}
      />
    </div>
  );
};
