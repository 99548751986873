import { useContext } from "react";
import { RejectCandidateContext } from "./reject-candidate-context";

export const useRejectCandidate = () => {
  const {
    rejectCandidateData,
    resetRejectCandidateData,
    updateRejectCandidateData,
    previousSelectCandidates,
    setPreviousSelectCandidates,
    openDrawer,
    setOpenDrawer,
  } = useContext(RejectCandidateContext);

  return {
    rejectCandidateData,
    resetRejectCandidateData,
    updateRejectCandidateData,
    previousSelectCandidates,
    setPreviousSelectCandidates,
    openDrawer,
    setOpenDrawer,
  };
};
