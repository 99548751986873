import React from "react";
import { Select } from "~/scalis-components/core/select/select";
import {
  FIELD_NAME_DEFAULT_MESSAGE,
  LABEL_SELECT_DEFAULT_MESSAGE,
} from "../../employer-message.constants";
import { SelectDefaultMessageProps } from "./select-default-message.types";

export const SelectDefaultMessage: React.FC<SelectDefaultMessageProps> = ({
  defaultMessages,
  onValueChange,
  value,
  error,
  fieldName = FIELD_NAME_DEFAULT_MESSAGE,
}) => {
  return (
    <Select
      name={fieldName}
      label={LABEL_SELECT_DEFAULT_MESSAGE}
      options={defaultMessages.map(message => ({
        label: message.title ?? "",
        value: `${message.id}`,
      }))}
      className="w-full"
      setValue={onValueChange}
      value={value}
      error={error}
    />
  );
};
