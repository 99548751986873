"use client";

import React from "react";
import {
  DIALOG_NO_RESULTS_TITLE,
  DIALOG_NO_RESULTS_DESCRIPTION,
} from "./stage-collaborators-dialog-no-results.constants";
import { EmptyState } from "~/scalis-components/core";

export const StageCollaboratorsDialogNoResults = () => {
  return (
    <div className="p-2">
      <EmptyState
        icon="fa-regular fa-empty-set text-icon-neutral-20"
        iconContainerClassName="size-12"
        iconSize="text-xl"
        title={DIALOG_NO_RESULTS_TITLE}
        message={DIALOG_NO_RESULTS_DESCRIPTION}
      />
    </div>
  );
};
