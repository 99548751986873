import React from "react";
import Link from "next/link";
import { Button } from "~/scalis-components/core";
import { ActionButtonsProps } from "./action-buttons.types";

export const ActionButtons = ({
  actionBarItems,
  candidates,
}: ActionButtonsProps) => {
  const filteredActionBarItems = actionBarItems.filter(({ renderIf }) => {
    if (renderIf && !renderIf(candidates[0])) return false;
    return true;
  });

  const renderButton = ({
    id,
    label,
    href,
    renderIf,
    ...props
  }: ActionButtonsProps["actionBarItems"][0]) => {
    const onClick = () => props.onClick?.(candidates);

    if (href) {
      return (
        <Link key={id} href={href}>
          <Button text={label} {...props} onClick={onClick} />
        </Link>
      );
    }

    return <Button key={id} text={label} {...props} onClick={onClick} />;
  };

  return filteredActionBarItems.map(renderButton);
};
