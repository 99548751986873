import { tv } from "tailwind-variants";

export const tabs = tv({
  slots: {
    container: "h-full w-full flex flex-col justify-start items-start gap-4",
    list: "h-12 w-full justify-start p-0 rounded-none border-x-0 border-b border-t-0 border-solid border-borders",
    trigger: [
      "relative group hover:text-typography-high-contrast py-0 my-3 rounded-none border-y-0 border-l-0 border-r last:border-r-0 border-borders text-sm text-typography-low-contrast",
      "data-[state=active]:text-typography-color data-[state=active]:hover:text-typography-color data-[state=active]:shadow-none data-[state=active]:after:bg-typography-color",
      "after:absolute after:top-8 after:left-0 after:right-0 after:h-0.5 after:content-['']",
    ],
    triggerIcon: [
      "mr-1 h-5 w-5 text-typography-low-contrast group-hover:text-typography-high-contrast",
      "group-data-[state=active]:text-typography-color group-data-[state=active]:hover:text-typography-color",
    ],
  },
});
