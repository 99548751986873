import { Header } from "@tanstack/react-table";
import React from "react";
import { Icon } from "~/scalis-components/core/icon";
import { iconButtonClassName } from "~/scalis-components/core/icon/icon.styles";
import { cn } from "~/utils/cn";

type SortIconProps = {
  header: Header<unknown, unknown>;
  sorting: { id: string; desc: boolean }[];
};

export const SortColumnIcon: React.FC<SortIconProps> = ({
  header,
  sorting,
}) => {
  const getIcon = () => {
    if (sorting[0]?.id === header.column.id && !sorting[0]?.desc) {
      return "fa-solid fa-sort-up";
    }

    if (sorting[0]?.id === header.column.id && sorting[0]?.desc) {
      return "fa-solid fa-sort-down";
    }

    return "fa-solid fa-sort";
  };

  const icon = getIcon();

  return (
    <button
      type="button"
      onClick={header.column.getToggleSortingHandler()}
      className={cn(iconButtonClassName, "relative hover:bg-surface-brand-hover active:bg-button-brand-primary-light-pressed")}
    >
      <Icon icon={icon} />
    </button>
  );
};
