import { NameEnum } from "~/bff/graphql/generated/graphql";

export const getMilestoneStatusTagColor = (milestoneType?: NameEnum) => {
  switch (milestoneType) {
    case NameEnum.Sourcing:
      return "bg-dataviz-violet-40";
    case NameEnum.ApplicationReview:
      return "bg-dataviz-lilac-blue-40";
    case NameEnum.PreScreen:
      return "bg-dataviz-cyan-40";
    case NameEnum.Interviewing:
      return "bg-dataviz-teal-40";
    case NameEnum.Offer:
      return "bg-dataviz-lime-40";
    case NameEnum.Hired:
      return "bg-dataviz-yellow-40";
    default:
      return "";
  }
};
