import { TabsTrigger } from "~/components/v2/Tabs"; // TODO: move component
import { tabs } from "../../candidate-tab-view.styles";
import { TabTriggerProps } from "./tab-trigger.types";

export const TabTrigger = ({ id, Icon, viewName }: TabTriggerProps) => {
  const { trigger } = tabs();

  return (
    <TabsTrigger className={trigger()} value={id} key={viewName}>
      <Icon />
      {viewName}
    </TabsTrigger>
  );
};
