import React from "react";
import { Editor } from "~/components/v2/Editor";
import { cn } from "~/utils/cn";
import { FIELD_NAME_CUSTOM_MESSAGE } from "../../employer-message.constants";
import { CustomMessageProps } from "./custom-message.types";
import { LABEL_CUSTOM_MESSAGE } from "./custom-message.contants";

export const CustomMessage: React.FC<CustomMessageProps> = ({
  isDisabled,
  value,
  error,
  onValueChange,
  triggerValidation,
  placeholder,
  editorContentClassname,
  label = LABEL_CUSTOM_MESSAGE,
  fieldName = FIELD_NAME_CUSTOM_MESSAGE,
}) => {
  const [customMessage, setCustomMessage] = React.useState<string>(
    value || '<p class="m-0 mb-2 relative"><br></p>',
  );
  const initialValue = React.useRef(value);

  React.useEffect(() => {
    initialValue.current = value;
    onValueChange(fieldName, customMessage);
  }, [customMessage]);

  const deferredValue = React.useDeferredValue(value);
  React.useEffect(() => {
    if (initialValue.current && deferredValue !== initialValue.current) {
      triggerValidation?.(fieldName);
    }
  }, [deferredValue, isDisabled]);

  return (
    <div className="flex flex-col gap-1">
      <label
        className={cn(
          "opaticy-50 text-sm text-typography-high-contrast",
          {
            "text-typography-low-contrast": isDisabled,
          },
        )}
      >
        {label}
      </label>
      <Editor
        setValue={setCustomMessage}
        disabled={isDisabled}
        key={isDisabled ? "disabled" : "enabled"}
        placeholder={
          placeholder ?? "Insert your message to the candidate here..."
        }
        contentClassName={editorContentClassname}
      />
      {error && (
        <span className="flex items-center gap-1 text-xs text-error">
          <i className="mi-circle-error"></i>
          {error}
        </span>
      )}
    </div>
  );
};
