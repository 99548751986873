export enum ScheduleOptionEnum {
  ScheduleLater = 0,
  RequestAvailability = 1,
  SendSchedulerLink = 2,
}

export const SCHEDULE_VALUES = [
  "LATER",
  "REQUEST_AVILABILITY",
  "SCHEDULER",
] as const;

export const SCHEDULE_OPTIONS_LABELS = {
  SCHEDULE_LATER: "Schedule Later",
  REQUEST_AVAILABILITY: "Request candidate availability",
  SEND_SCHEDULER_LINK: "Send Scheduler Link",
} as const;

export const FIELD_NAME_SCHEDULE_RADIO = "interview_schedule";
export const LABEL_SCHEDULE_RADIO = "Interview Scheduling";
