import { PopUpType } from "~/scalis-components/pipeline/pipeline.types";

export const POPUP_TYPE_ALREADY_INVITED_TO_SAME_JOB_MODAL =
  "already_invited_same_job_modal";
export const POPUP_TYPE_ALREADY_INVITED_TO_ANOTHER_JOB_MODAL =
  "already_invited_another_job_modal";
export const POPUP_TYPE_ALREADY_MATCHED_TO_JOB_MODAL =
  "already_matched_to_job_modal";

export const TEXTS = {
  [PopUpType.ALREADY_INVITED_TO_SAME_JOB_MODAL]: {
    title: "Candidate already invited",
    header: "This candidate has already been invited to",
    footer: "Candidates can only be invited to apply to a job once.",
  },
  [PopUpType.ALREADY_INVITED_TO_ANOTHER_JOB_MODAL]: {
    title: "Candidate already invited to another job",
    header: "This candidate has already been invited to",
    footer:
      "If they do not take action within 5 days, you will be able to reach out to them regarding other jobs again.",
  },
  [PopUpType.ALREADY_MATCHED_TO_JOB_MODAL]: {
    title: "Candidate already matched to job",
    header: "This candidate has already been matched to",
    footer:
      "Please go to Sourcing stage of the job in order to see the candidate's profile.",
  },
};

export const POPUP_TYPES_APPLICATION_ALREADY_EXISTS_MODAL = [
  PopUpType.ALREADY_INVITED_TO_ANOTHER_JOB_MODAL,
  PopUpType.ALREADY_INVITED_TO_SAME_JOB_MODAL,
  PopUpType.ALREADY_MATCHED_TO_JOB_MODAL,
];
