import React from "react";
import { useFormContext } from "react-hook-form";

import { TemplateMessage } from "~/bff/graphql/generated/graphql";
import { EmployerMessage } from "~/scalis-components/pipeline/candidate-drawer";
import {
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_MESSAGE,
  FORM_FIELDS,
} from "../../scheduler-link-form.constants";
import { MessageSectionProps } from "./message-section.types";

export const MessageSection: React.FC<MessageSectionProps> = ({
  defaultMessages,
}) => {
  const { watch, trigger, setValue } = useFormContext();

  return (
    <div className="mb-6">
      <h2 className="mb-4 text-base font-medium text-neutral-primary">
        {FORM_FIELDS[FIELD_NAME_CUSTOM_MESSAGE].description}
      </h2>
      <EmployerMessage
        defaultMessages={defaultMessages as unknown as TemplateMessage[]}
        onValueChange={setValue}
        triggerValidation={trigger}
        customMessage={watch(FIELD_NAME_CUSTOM_MESSAGE)}
        defaultMessage={watch(FIELD_NAME_MESSAGE)}
        defaultMessageError={FORM_FIELDS[FIELD_NAME_MESSAGE].errorMessage}
        editorContentClassname="h-28"
      />
    </div>
  );
};
