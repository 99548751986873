"use client";

import { Header } from "@tanstack/react-table";
import { ActionsMenu } from "../../actions-menu/actions-menu";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { useListViewHeader } from "../use-list-view-header";

interface HeaderCellActionsProps {
  header: Header<unknown, unknown>;
}

export const HeaderCellActions: React.FC<HeaderCellActionsProps> = ({
  header,
}) => {
  const { getHeaderActionsMenu } = useListViewHeader();

  const actionsMenuItems = getHeaderActionsMenu(header);

  return (
    <ActionsMenu
      isHeader
      actionsMenuItems={actionsMenuItems}
      tooltipText="Actions"
    />
  );
};
