import { ActionProps } from "./action.types";
import { ActionTemplate } from "./action-template";
import {
  AdvanceIcon,
  ChatIcon,
  RejectIcon,
} from "~/src/app/company/(dashboard)/(applications)/pipeline/components/icons";
import { ACTIONS } from "./action.constants";

export const Action: React.FC<ActionProps> = props => {
  switch (props.action) {
    case ACTIONS.advance:
      return (
        <ActionTemplate {...props}>
          Advance <AdvanceIcon />
        </ActionTemplate>
      );
    case ACTIONS.chat:
      return (
        <ActionTemplate {...props}>
          Chat <ChatIcon />
        </ActionTemplate>
      );
    case ACTIONS.invite_to_apply:
      return <ActionTemplate {...props}>Invite to Apply</ActionTemplate>;
    case ACTIONS.match_to_job:
      return <ActionTemplate {...props}>Match to Job</ActionTemplate>;
    case ACTIONS.reject:
      return (
        <ActionTemplate {...props}>
          Reject <RejectIcon width={10} height={10} />
        </ActionTemplate>
      );
    case ACTIONS.reject_x:
      return (
        <ActionTemplate {...props}>
          <RejectIcon />
        </ActionTemplate>
      );
  }
};
