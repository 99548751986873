import { Transform, CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";

export const dndStyles = ({
  transform,
  transition,
  isSorting,
}: {
  transform: Transform | null;
  transition?: string;
  isSorting: boolean;
}): CSSProperties => {
  return {
    transform: isSorting ? undefined : CSS.Translate.toString(transform),
    transition: transition,
  };
};

export const thead = tv({
  base: "sticky top-0 z-30 flex bg-white",
  variants: {
    shadow: { true: "animate-scroll-shadow" },
  },
});

export const th = tv({
  base: cn(
    "group relative select-none text-left text-sm/6 last:flex-1",
    "font-normal text-neutral-secondary after:absolute after:bottom-0",
    "after:block after:h-full after:w-1 after:content-[''] first:text-center",
    "h-10 hover:bg-brand-primary-light-00",
  ),
  variants: {
    isHidden: { true: "!z-50" },
    hasHoverBg: { false: "bg-white hover:bg-white" },
    insertPosition: {
      before: "after:left-0 after:bg-actions-muted",
      after: "after:right-0 after:bg-actions-muted",
      false: "",
    },
    isPinned: { true: "sticky z-30 bg-white" },
    isDragging: { true: "bg-background-pressed" },
    colorColumn: {
      true: "before:absolute before:bottom-0 before:left-0 before:top-0 before:z-40 before:w-2 before:rounded-tl-lg before:content-['']",
    },
    tableColor: {
      "01": "before:bg-dataviz-violet",
      "02": "before:bg-dataviz-lilac-blue",
      "03": "before:bg-dataviz-cyan",
      "04": "before:bg-dataviz-teal",
      "05": "before:bg-dataviz-lime",
      "06": "before:bg-dataviz-yellow",
    },
  },
});

export const container = tv({
  base: "relative flex h-full items-center p-2 ",
  variants: {
    hasActions: { true: "justify-between", false: "justify-center" },
    hasBorder: {
      true: "border-b border-r border-t border-solid border-neutral-30",
      false: "w-inherit",
    },
    select: { true: "first:rounded-tl-lg" },
  },
});

export const contentDiv = tv({
  base: "text-nowrap group-hover:visible",
  variants: {
    isHidden: { true: "invisible hover:bg-white" },
    hasElipseContent: { true: "overflow-hidden text-ellipsis" },
  },
});
