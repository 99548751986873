import React from "react";
import { Body, BodySize, BodyType } from "~/scalis-components/core/typography";
import { StageStatusTag } from "../stage-status";
import { ApplicationStatusTag } from "../application-status";
import { StageApplicationStatusTagProps } from "./stage-application-status-tag.types";

export const StageApplicationStatusTag = ({
  milestoneType,
  stageName,
  applicationStatus,
  lastUpdatedStatusAt,
}: StageApplicationStatusTagProps) => {
  return (
    <StageStatusTag
      className="h-8 pr-1"
      milestoneType={milestoneType}
      stageName={stageName}
      small
      text={
        <div className="flex items-center gap-[6px]">
          {stageName && (
            <Body size={BodySize.small} type={BodyType.emphasys}>
              {stageName}
            </Body>
          )}
          {applicationStatus && (
            <ApplicationStatusTag
              applicationStatus={applicationStatus}
              lastUpdatedStatusAt={lastUpdatedStatusAt}
            />
          )}
        </div>
      }
    />
  );
};
