"use client";

import React, { createContext, useState } from "react";
import {
  Candidate,
  RejectCandidateContextProps,
  RejectCandidateData,
  RejectContextProviderProps,
} from "./reject-candidate-context.types";
import { DEFAULT_SELECT_CANDIDATE_FORM_DATA } from "./reject-candidate-context.contants";

export const RejectCandidateContext =
  createContext<RejectCandidateContextProps>({} as RejectCandidateContextProps);

export const RejectCandidateContextProvider: React.FC<
  RejectContextProviderProps
> = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const [rejectCandidateData, setRejectCandidateData] =
    useState<RejectCandidateData>(DEFAULT_SELECT_CANDIDATE_FORM_DATA);

  const [previousSelectCandidates, setPreviousSelectCandidates] = useState<
    Candidate[]
  >([]);

  const updateRejectCandidateData = (data: Partial<RejectCandidateData>) => {
    setRejectCandidateData(prevData => ({
      ...prevData,
      ...data,
    }));
  };

  const resetRejectCandidateData = () => {
    setRejectCandidateData(DEFAULT_SELECT_CANDIDATE_FORM_DATA);
  };

  return (
    <RejectCandidateContext.Provider
      value={{
        rejectCandidateData,
        updateRejectCandidateData,
        resetRejectCandidateData,
        previousSelectCandidates,
        setPreviousSelectCandidates,
        openDrawer,
        setOpenDrawer,
      }}
    >
      {children}
    </RejectCandidateContext.Provider>
  );
};
