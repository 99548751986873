import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "~/components/v2/DropdownMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { ActionsMenuProps, AlignMenu } from "./actions-menu.types";
import { ActionsMenuItems } from "../../list-view.types";
import React, { useMemo } from "react";
import { Icon } from "~/scalis-components/core/icon";
import { cn } from "~/utils/cn";
import { ItemContent } from "./components/item-content";
import { iconButtonClassName } from "~/scalis-components/core/icon/icon.styles";

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  actionsMenuItems,
  alignMenu = AlignMenu.start,
  tooltipText = "",
  row,
  isHeader = false,
}) => {
  const filteredActions = useMemo(() => {
    return actionsMenuItems.map((groupItem: ActionsMenuItems[]) => {
      return groupItem.filter((item: ActionsMenuItems) => {
        if (item.renderIf) return item.renderIf(row?.original);
        return true;
      });
    });
  }, [actionsMenuItems]);

  const GroupMenuItems = ({ groupItem }: { groupItem: ActionsMenuItems[] }) => {
    return (
      <DropdownMenuGroup>
        {groupItem.map(item => (
          <DropdownMenuItem
            key={item.label}
            className={cn(
              "group flex h-9 p-0 hover:cursor-pointer hover:bg-button-subtle-hover",
              { "gap-2 p-2": !item.href },
            )}
            onClick={event => {
              !!item.onClick && item.onClick(row?.original ?? event);
            }}
          >
            <ItemContent item={item} />
          </DropdownMenuItem>
        ))}
      </DropdownMenuGroup>
    );
  };

  return (
    <div className="flex w-full items-center justify-center">
      <DropdownMenu modal={false}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger className="focus:outline-none" asChild>
                <button
                  type="button"
                  className={cn(iconButtonClassName, isHeader && "hover:bg-surface-brand-hover active:bg-button-brand-primary-light-pressed")}
                >
                  <Icon icon="fa-solid fa-ellipsis text-icon-neutral-20" />
                </button>
              </DropdownMenuTrigger>
            </TooltipTrigger>
            {!!tooltipText && (
              <TooltipContent>
                <p className="z-50">{tooltipText}</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>

        <DropdownMenuContent
          align={alignMenu}
          className="w-48 rounded-xl bg-white p-2 text-neutral-primary shadow-sm"
        >
          {filteredActions.map((groupItem, index) => {
            return (
              <React.Fragment key={`action-group-${index}`}>
                {index > 0 && (
                  <DropdownMenuSeparator className="mx-3 bg-borders-borders" />
                )}
                <GroupMenuItems groupItem={groupItem} />
              </React.Fragment>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
