"use client";

import { flexRender } from "@tanstack/react-table";

import { forwardRef, useEffect } from "react";
import { getCommonPinningStyles } from "../../../list-view.utils";
import { containerDiv, content, td } from "../list-view-body.styles";
import { useListViewBody } from "../use-list-view-body";
import { BodyCellProps } from "./body-cell.types";

export const BodyCell = forwardRef<HTMLDivElement, BodyCellProps>(
  ({ cell, attributes, listeners, isSelected, active }, ref) => {
    const { tableColor, getSylesVariants } = useListViewBody();

    useEffect(() => {
      const { meta } = cell.column.columnDef;

      if (meta?.isPinned && !cell.column.getIsPinned()) {
        cell.column.pin("left");
      }
    });

    const { meta } = cell.column.columnDef;
    const metaRows = meta?.rows ?? { styles: {} };

    const {
      isActive,
      isHidden,
      hasBorder,
      hasElipseContent,
      hasHoverBg,
      isPinned,
      colorColumn,
      isLastColumn,
      isHideHeaderShadow,
      isColoredColumn,
    } = getSylesVariants({
      cell,
      isSelected,
      active,
    });

    return (
      <div
        ref={metaRows?.dndNodeRef ? ref : null}
        style={{ ...getCommonPinningStyles(cell.column) }}
        className={td({
          isActive,
          isHidden,
          hasHoverBg,
          isPinned,
          colorColumn,
          isLastColumn,
          tableColor,
          isHideHeaderShadow,
        })}
        role="cell"
      >
        <div
          className={containerDiv({
            isHidden,
            hasBorder,
            hasElipseContent,
            isColoredColumn,
          })}
          {...(metaRows?.isDraggable && { ...attributes, ...listeners })}
        >
          <span
            className={content({
              hasElipseContent,
            })}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </span>
        </div>
      </div>
    );
  },
);
