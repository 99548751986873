"use client";

import { months } from "moment";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { z } from "zod";
import Dialog from "~/components/jobseeker/Dialog";
import {
  EditForm,
  NumberField,
  RadioField,
  SelectField,
  TextAreaField,
  TextField,
} from "~/components/jobseeker/EditForm";
import { LocationField } from "~/components/jobseeker/EditForm/LocationField";
import { Checkbox } from "~/components/v2/Checkbox";
import { Combobox } from "~/components/v2/Combobox";
import { FormField } from "~/components/v2/Form";
import { useCreateJobExperience } from "~/hooks/jobseeker/useCreateJobExperience";
import { useDeleteJobExperience } from "~/hooks/jobseeker/useDeleteJobExperience";
import { useUpdateJobExperience } from "~/hooks/jobseeker/useUpdateJobExperience";
import useCustomForm from "~/hooks/useCustomForm";
import Add from "~/icons/Add";
import { jobExperience } from "~/types/jobseeker";
import { industries, generateSubIndustriesOptions } from "~/utils/industries";
import { formSchema } from "./schema";
import { jobTypeOptions, locationTypeOptions, sizeMap } from "./utils";

interface ExperienceProps {
  data?: jobExperience;
  uid?: number;
  currentJobs?: jobExperience[];
  onSubmit: () => void;
}

const sanitizeData = (data: z.infer<typeof formSchema>) => {
  const map = { YES: true, NO: false };
  const sanitizedData = {
    ...data,
    management_position: data.management_position
      ? map[data.management_position]
      : undefined,
  };
  return sanitizedData;
};

const Experience: FC<ExperienceProps> = ({
  data,
  onSubmit,
  currentJobs = [],
  uid,
}) => {
  const { mutateAsync: update } = useUpdateJobExperience();
  const { mutateAsync: create } = useCreateJobExperience();
  const { mutateAsync: deleteItem, isPending: isDeleting } =
    useDeleteJobExperience();
  const [teamSize, setTeamSize] = useState<string>("");
  const [toEnd, setToEnd] = useState<number[]>([]);

  const onReset = () => {
    setTeamSize("");
    setValue("location", "");
    setValue("is_currently_working", null);
    setValue("remote_or_inperson", null);
    setValue("how_large_team_min", null);
    setValue("how_large_team_max", null);
    setValue("start_year", null);
    setValue("end_year", null);
    setValue("start_month", null);
    setValue("end_month", null);
    setValue("hourly_salary", null);
    setValue("salary", "");
    setValue("plus_comission", null);
    setValue("management_position", null);
  };

  const {
    register,
    handleSubmit,
    errorMessages,
    isDirty,
    reset,
    setValue,
    control,
    watch,
    submit,
    handleDelete,
    isValid,
    trigger,
  } = useCustomForm({
    update: newData => update({ uid: uid!, data: sanitizeData(newData) }),
    create: newData =>
      create({
        uid: uid!,
        data: { create: sanitizeData(newData), toEnd },
      }),
    deleteItem: id => deleteItem({ uid: uid!, id }),
    formSchema,
    data,
    onSubmit,
    onReset,
  });

  useEffect(() => {
    if (!data) return;
    const teamSizeState = Object.entries(sizeMap).find(
      ([_, { how_large_team_min, how_large_team_max }]) =>
        how_large_team_min === data.how_large_team_min &&
        how_large_team_max === data.how_large_team_max,
    );
    setTeamSize(teamSizeState?.[0] || "");
    if (data.management_position || data.management_position === false) {
      setValue("management_position", data.management_position ? "YES" : "NO");
    }
  }, [data]);

  const industry = watch("field_industry");
  const currentPosition = watch("is_currently_working");

  useEffect(() => {
    if (!isDirty) return;
    setValue("field_sub_industry", undefined);
  }, [industry]);

  useEffect(() => {
    if (currentPosition === "YES") {
      setValue("end_month", null);
      setValue("end_year", null);
    }
  }, [currentPosition]);

  const handleTeamSize = (_: string, value: string) => {
    setValue("how_large_team_min", sizeMap[value].how_large_team_min);
    setValue("how_large_team_max", sizeMap[value].how_large_team_max);
    setTeamSize(value);
  };

  const toggleToEnd = (id: number) => (checked: boolean) => {
    if (checked) {
      setToEnd(currentState => [...currentState, id]);
      return;
    }

    setToEnd(currentState => {
      const index = currentState.indexOf(id);
      const copy = [...currentState];
      copy.splice(index, 1);
      return copy;
    });
  };

  return (
    <EditForm
      title="Experience"
      subTitle={data ? "Edit" : "Add new"}
      onSubmit={submit}
      submitHandler={handleSubmit}
      iconTrigger={!data ? <Add /> : undefined}
      isDirty={isDirty}
      reset={reset}
      onDelete={data ? handleDelete : undefined}
      isDeleting={isDeleting}
      isValid={isValid}
      needsValidity={!data}
    >
      <TextField
        label="Employer"
        {...register("employer")}
        error={errorMessages.employer}
        required
      />

      <RadioField
        control={control}
        label="Are you currently working in this role?"
        {...register("is_currently_working")}
        options={[
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ]}
        required
        error={errorMessages.is_currently_working}
      />
      <TextField
        label="Job title"
        {...register("job_title")}
        error={errorMessages.job_title}
        required
      />
      <SelectField
        label="Job type"
        {...register("job_type")}
        error={errorMessages.job_type}
        control={control}
        options={jobTypeOptions}
        setValue={setValue}
        required
      />
      <LocationField
        label="Location"
        {...register("location")}
        error={errorMessages.location}
        setValue={(name, data) => setValue(name, data.description)}
        control={control}
        required
        isCityName={true}
      />
      <RadioField
        control={control}
        label="Location type"
        {...register("remote_or_inperson")}
        options={locationTypeOptions}
        error={errorMessages.remote_or_inperson}
        required
      />
      <div>
        <span className="text-sm font-semibold">Start date*</span>
        <div className="grid grid-cols-[2fr_1fr] gap-4">
          <SelectField
            control={control}
            {...register("start_month")}
            options={months().map((label, i) => ({
              label,
              value: `${i}`,
            }))}
            label=""
            setValue={setValue}
            placeholder="Month"
            searchByLabel
            error={errorMessages.start_month}
          />
          <SelectField
            control={control}
            {...register("start_year")}
            options={new Array(200).fill(100).map((_, i) => ({
              label: `${new Date().getFullYear() - i}`,
              value: `${new Date().getFullYear() - i}`,
            }))}
            label=""
            setValue={setValue}
            placeholder="Year"
            error={errorMessages.start_year}
          />
        </div>
      </div>
      {currentPosition === "NO" && (
        <div>
          <span className="text-sm font-semibold">
            End date{currentPosition === "NO" && "*"}
          </span>
          <div className="grid grid-cols-[2fr_1fr] gap-4">
            <SelectField
              control={control}
              {...register("end_month")}
              options={months().map((label, i) => ({
                label,
                value: `${i}`,
              }))}
              label=""
              setValue={setValue}
              placeholder="Month"
              searchByLabel
              error={errorMessages.end_month}
            />
            <SelectField
              control={control}
              {...register("end_year")}
              options={new Array(200).fill(100).map((_, i) => ({
                label: `${new Date().getFullYear() - i}`,
                value: `${new Date().getFullYear() - i}`,
              }))}
              label=""
              setValue={setValue}
              placeholder="Year"
              error={errorMessages.end_year}
            />
          </div>
        </div>
      )}
      {currentPosition === "YES" &&
        currentJobs.map(job => (
          <div key={job.id} className="flex items-start gap-1">
            <Checkbox
              id={`current-job-${job.id}`}
              className="mt-1 border-white-90 text-white data-[state=checked]:border-dark-color-text-100 data-[state=checked]:bg-dark-color-text-100"
              onCheckedChange={toggleToEnd(job.id)}
              checked={toEnd.includes(job.id)}
            />
            <label htmlFor={`current-job-${job.id}`}>
              End current position {job.job_title} - {job.employer}
            </label>
          </div>
        ))}
      <SelectField
        control={control}
        options={industries}
        label="Industry"
        setValue={setValue}
        {...register("field_industry")}
        error={errorMessages.field_industry}
        required
      />

      <SelectField
        control={control}
        options={generateSubIndustriesOptions(industry)}
        label="Sub-industry"
        setValue={setValue}
        disabled={!industry}
        required
        {...register("field_sub_industry")}
        error={errorMessages.field_sub_industry}
      />
      <div>
        <span className="flex gap-1 text-sm font-semibold">
          Salary (Optional)
          <Dialog
            infoDialog
            className="max-w-[375px]"
            trigger={<i className="mi-circle-information"></i>}
            title="Salary information"
            description={
              <p className="text-center text-xs">
                Salary information will not be shared with employers. It is for
                our internal algorithms to help match you to jobs and find
                relevant opportunities for you.
              </p>
            }
          />
        </span>
        <div className="grid grid-cols-2 gap-4">
          <Controller
            control={control}
            name="salary"
            render={({ field: { onChange, name, value } }) => (
              <NumberField
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale
                name={name}
                value={value}
                onValueChange={values =>
                  onChange({
                    target: { name, value: values.floatValue || 0 },
                  })
                }
                label=""
                error={errorMessages.salary}
                startAdornment={<span className="font-bold">$</span>}
              />
            )}
          />
          <SelectField
            className="mt-1"
            control={control}
            name="hourly_salary"
            options={[
              { label: "Hourly", value: "HOURLY" },
              { label: "Salary", value: "SALARY" },
            ]}
            label=""
            setValue={setValue}
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <FormField
          control={control}
          name="plus_comission"
          render={({ field: { value, onChange } }) => (
            <Checkbox
              id={`plusCommission`}
              checked={value}
              onCheckedChange={onChange}
              className="border-white-90 text-white data-[state=checked]:border-dark-color-text-100 data-[state=checked]:bg-dark-color-text-100"
            />
          )}
        />
        <label htmlFor="plusCommission">Plus commission?</label>
      </div>
      <SelectField
        control={control}
        {...register("management_position")}
        options={[
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ]}
        label="Is / was a management position?"
        setValue={setValue}
        required
        error={errorMessages.management_position}
      />
      <div className="flex flex-col">
        <span className="mb-1 text-sm font-semibold">
          How large of a team did you lead?*
        </span>
        <Combobox
          multi={false}
          name="teamSize"
          ref={register("how_large_team_min").ref}
          options={[
            { value: "0", label: "N/A" },
            { value: "1_TO_2", label: "1 to 2" },
            { value: "3_TO_5", label: "3 to 5" },
            { value: "6_TO_10", label: "6 to 10" },
            { value: "11_TO_20", label: "11 to 20" },
            { value: "21", label: "21+" },
          ]}
          setValue={handleTeamSize}
          value={teamSize}
          placeholder="Example"
          emptyTerm="item"
          searchPlaceholder="Search..."
          className={`h-[2.625rem] py-2 ${
            teamSize ? "border-white-70" : "border-white-90 text-white-85"
          } ${
            errorMessages.how_large_team_min &&
            "border-error bg-error-40 text-error placeholder-shown:border-error"
          }`}
          icon={<i className="mi-chevron-down h-4 w-4" />}
          onBlur={() => trigger("how_large_team_min")}
        />
        {errorMessages.how_large_team_min && (
          <span className="flex items-center gap-1 text-xs text-error">
            <i className="mi-circle-error"></i>
            {errorMessages.how_large_team_min}
          </span>
        )}
      </div>
      <TextAreaField
        {...register("s_job_description")}
        label="Short-Form Job Description"
        subText="This description to employers and recruiters. Try to make it brief and concise yet informative about what you did in your job, what tasks you completed or were in charge of, and what accomplishments you had. It should be resume length."
        error={errorMessages.s_job_description}
        required
      />
      <TextAreaField
        {...register("l_job_description")}
        label="Long-Form Job Description (optional)"
        subText="This description will not be visible to employers and recruiters, and is designed for job matching purposes only - please be as descriptive as possible regarding your role, your responsibilities, your accomplishments, etc."
        error={errorMessages.l_job_description}
      />
    </EditForm>
  );
};

export default Experience;
