import { cn } from "~/utils/cn";
import { CandidateNameFooterProps } from "./candidate-name-footer.types";

export const CandidateNameFooter: React.FC<CandidateNameFooterProps> = ({
  showSeeAllCandidates,
  allCandidatesCallback,
}) => {
  if (!showSeeAllCandidates) {
    return null;
  }

  return (
    <div
      className={cn(
        "w-full rounded-b-xl border-b border-l border-r border-neutral-30 p-2",
        "hover:bg-brand-primary-light-00 active:bg-brand-primary-light-10",
      )}
      onClick={allCandidatesCallback}
    >
      <div className="flex w-full select-none items-center gap-2 text-sm/6 font-medium text-brand-primary-rest hover:cursor-pointer">
        <span>See All Candidates</span>
        <i className="fa-regular fa-plus" />
      </div>
    </div>
  );
};
