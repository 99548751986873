import { tv } from "tailwind-variants";

export const statusStyles = tv({
  base: "flex items-center h-6 gap-1 whitespace-nowrap rounded-full bg-background-muted px-3 py-1 text-sm",
  variants: {
    type: {
      PROSPECT: "bg-chardonnay-200",
      INVITED_TO_APPLY: "bg-forest-green",
      INVITE_REJECTED: "bg-monza-300",
      SOURCING: "bg-extra-01",
      APPLICATION_REVIEW: "bg-extra-02",
      SCREENING: "bg-extra-03",
      INTERVIEW_ROUND: "bg-extra-04",
      INTERVIEW_SCHEDULED: "bg-extra-04",
      INTERVIEW_REQUESTED: "bg-extra-04",
      INTERVIEW_RESEND: "bg-extra-04",
      INTERVIEW_COMPLETED: "bg-extra-04",
      JOB_OFFER: "bg-extra-05",
      HIRED: "bg-extra-06",
      INTERVIEW_NOT_SCHEDULED: "bg-monza-200",
      REJECTED: "bg-monza-300",
    },
  },
});
