import { ReactNode } from "react";

export const VIEW_TYPES = {
  CARDS: "CARDS",
  KANBAN: "KANBAN",
  LIST: "LIST",
} as const;

export const VIEW_NAMES = {
  CARDS: "Cards",
  KANBAN: "Kanban",
  LIST: "List",
} as const;

export type ViewType = keyof typeof VIEW_TYPES;
export type ViewName = (typeof VIEW_NAMES)[keyof typeof VIEW_NAMES];

export interface View {
  type: ViewType;
  Component: ReactNode;
}

export interface DetailedView {
  id: ViewType;
  viewName: ViewName;
  Component: ReactNode;
  Icon: () => ReactNode;
}

export interface CandidateTabViewProps {
  views: View[];
  tabsContentClassName?: string;
  candidateFilters?: any; // TODO: Remove after pipeline listview integration
}

export interface CandidateTabViewTabsData {
  tabTriggers: ReactNode[];
  tabContents: ReactNode[];
}
