export enum PopUpType {
  INVITE_SCALIS_CANDIDATE_DRAWER = "invite_scalis_candidate",
  INVITE_UPLOADED_CANDIDATE_DRAWER = "invite_uploaded_candidate",
  ALREADY_INVITED_TO_SAME_JOB_MODAL = "already_invited_to_same_job",
  ALREADY_INVITED_TO_ANOTHER_JOB_MODAL = "already_invited_to_another_job",
  ALREADY_MATCHED_TO_JOB_MODAL = "already_matched_to_job",
  ADVANCE_CANDIDATE_DRAWER = "advance_candidate_drawer",
  MATCH_CANDIDATE_DRAWER = "match_candidate_drawer",
  UPLOAD_CANDIDATE_DRAWER = "upload_candidate_drawer",
}
