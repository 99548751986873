import { useContext, useEffect } from "react";
import { GlobalListViewContext } from "./global-list-view-context";
import { UseGlobalListViewProps } from "./global-list-view-context.types";

export const useGlobalListView: React.FC<UseGlobalListViewProps> = ({
  id,
  selectedItems,
}) => {
  const { setTables, tables, setHideAllActionBars, hideAllActionBars } =
    useContext(GlobalListViewContext);

  useEffect(() => {
    const shouldUpdateTable =
      !tables || tables[id]?.selectedItems !== selectedItems;

    if (setTables && shouldUpdateTable) {
      setTables(prev => {
        return {
          ...prev,
          [id]: {
            selectedItems,
          },
        };
      });
    }
  }, [selectedItems]);

  useEffect(() => {
    if (tables) {
      const numberOfActionBars = Object.values(tables).reduce(
        (acc, { selectedItems }) => {
          if (selectedItems > 0) {
            acc++;
          }

          return acc;
        },
        0,
      );

      if (numberOfActionBars > 1) {
        setHideAllActionBars(true);
        return;
      }

      if (numberOfActionBars < 2 && hideAllActionBars) {
        setHideAllActionBars(false);
      }
    }
  }, [tables]);

  return null;
};
