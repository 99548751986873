import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { TemplateMessage } from "~/bff/graphql/generated/graphql";
import { EmployerMessage } from "~/scalis-components/pipeline";
import { SectionContainer } from "../../../section-container";
import {
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
} from "../../step-select-candidates.constants";
import { MessageSectionProps } from "./message-section.types";

export const MessageSection: React.FC<MessageSectionProps> = ({
  defaultMessages,
}) => {
  const { watch, trigger, setValue } = useFormContext();
  const { errors } = useFormState();

  return (
    <SectionContainer title="Message">
      <EmployerMessage
        defaultMessages={defaultMessages as unknown as TemplateMessage[]}
        onValueChange={setValue}
        triggerValidation={trigger}
        customMessage={watch(FIELD_NAME_CUSTOM_MESSAGE)}
        defaultMessage={watch(FIELD_NAME_DEFAULT_MESSAGE)}
        defaultMessageError={
          errors[FIELD_NAME_DEFAULT_MESSAGE]?.message as string
        }
        customMessageError={
          errors[FIELD_NAME_CUSTOM_MESSAGE]?.message as string
        }
        editorContentClassname="h-28"
      />
    </SectionContainer>
  );
};
