import { EmptyState } from "~/scalis-components/core";
import { LoadingStateProps } from "./loading-state.types";

export const LoadingState = (props: LoadingStateProps) => {
  return (
    <EmptyState
      title="Loading..."
      message="Please wait."
      icon="fa-regular fa-spinner-third fa-spin"
      iconSize="fa-3x"
      style={{ "--fa-animation-duration": "1s" }}
      {...props}
    />
  );
};
