import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { TemplateMessage } from "~/bff/graphql/generated/graphql";
import {
  CandidateDrawerNote,
  EmployerMessage,
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  NotificationRadio,
} from "~/scalis-components/pipeline/candidate-drawer";
import { JobSelect } from "../job-select/job-select";
import { FIELD_NAME_JOB_SELECT } from "../job-select/job-select.constants";
import { MatchCandidateDrawerContext } from "../match-candidate-drawer-context";
import {
  NOTIFICATION_RADIO_OPTIONS,
  UPLOADED_CANDIDATE_NOTE,
} from "./match-candidate-drawer-form.constants";
import {
  FormValues,
  MatchCandidateDrawerFormProps,
  createSchema,
} from "./match-candidate-drawer-form.types";

export const MatchCandidateDrawerForm: React.FC<
  MatchCandidateDrawerFormProps
> = ({ isSubmit, onErrorSubmit, onSubmit }) => {
  const {
    isStaticProfile,
    isNotifyCandidate,
    setIsNotifyCandidate,
    defaultMessages,
  } = React.useContext(MatchCandidateDrawerContext);

  const schema = createSchema(isStaticProfile, isNotifyCandidate);
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });
  const formRef = React.useRef<HTMLFormElement | null>(null);
  React.useEffect(() => {
    if (isSubmit) {
      formRef?.current?.requestSubmit();
    }
  }, [isSubmit]);

  return (
    <form
      ref={formRef}
      className="flex flex-col overflow-auto"
      onSubmit={handleSubmit(onSubmit!, onErrorSubmit)}
    >
      <div className="flex flex-col gap-6 px-6 py-4">
        <div>
          <JobSelect
            error={errors[FIELD_NAME_JOB_SELECT]?.message}
            onValueChange={setValue}
            value={watch(FIELD_NAME_JOB_SELECT)!}
          />
        </div>
        {!isStaticProfile ? (
          <>
            {" "}
            <div>
              <NotificationRadio
                onValueChange={(name: keyof FieldValues, value: string) => {
                  setValue(name as keyof FormValues, value);
                  setIsNotifyCandidate?.(!!parseInt(value));
                }}
                notificationOptions={NOTIFICATION_RADIO_OPTIONS}
                value={NOTIFICATION_RADIO_OPTIONS[1].value}
              />
            </div>
            {isNotifyCandidate && (
              <div>
                <EmployerMessage
                  onValueChange={setValue}
                  triggerValidation={trigger}
                  defaultMessages={
                    defaultMessages as unknown as TemplateMessage[]
                  }
                  customMessage={watch(FIELD_NAME_CUSTOM_MESSAGE)}
                  defaultMessage={watch(FIELD_NAME_DEFAULT_MESSAGE)}
                  defaultMessageError={
                    errors[FIELD_NAME_DEFAULT_MESSAGE]?.message
                  }
                  customMessageError={
                    errors[FIELD_NAME_CUSTOM_MESSAGE]?.message
                  }
                />
              </div>
            )}
          </>
        ) : (
          <CandidateDrawerNote note={UPLOADED_CANDIDATE_NOTE} />
        )}
      </div>
    </form>
  );
};
