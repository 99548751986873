import React from "react";

import { applicationStatusMap } from "~/utils/enum-mappers";
import { Tag } from "~/scalis-components/core";
import { Caption, CaptionType } from "~/scalis-components/core/typography";
import {
  formatStatusTime,
  getApplicationStatusTagColor,
} from "./application-status-tag.utils";
import { ApplicationStatusTagProps } from "./application-status-tag.types";

export const ApplicationStatusTag = ({
  applicationStatus,
  lastUpdatedStatusAt,
  ...props
}: ApplicationStatusTagProps) => {
  return (
    <Tag
      color={getApplicationStatusTagColor(applicationStatus)}
      text={
        <div className="flex gap-1 font-normal">
          <Caption type={CaptionType.emphasys}>
            {applicationStatusMap[applicationStatus]}
          </Caption>
          <Caption italic>{formatStatusTime(lastUpdatedStatusAt)}</Caption>
        </div>
      }
      small
      {...props}
    />
  );
};
