import { useS3Upload } from "next-s3-upload";
import { useMutation } from "@tanstack/react-query";
import logger from "~/utils/logger";

const createFile = async ({
  file,
  uploadToS3,
}: {
  file: File;
  uploadToS3: any;
}) => {
  try {
    return await uploadToS3(file);
  } catch (error) {
    logger.error(`createFile: ${error}`);
    return null;
  }
};

const useCandidateUploadFile = () => {
  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: createFile,
  });
  const { uploadToS3 } = useS3Upload();
  const handleUpload = async (
    file: File | FileList | null,
  ): Promise<string | undefined> => {
    if (!file) return;
    const response = await mutateAsync({
      file: file as File,
      uploadToS3,
    });
    return response?.url;
  };

  return { handleUpload, loading: isLoading };
};

export default useCandidateUploadFile;
