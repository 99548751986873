import { tv } from "tailwind-variants";

export const statusStyles = tv({
  variants: {
    type: {
      PROSPECT: "bg-dataviz-soft-orange-10",
      INVITED_TO_APPLY: "bg-dataviz-leaf-green-10",
      INVITE_DECLINED: "bg-dataviz-cherry-red-10",
      INTERVIEW_NOT_SCHEDULED: "bg-dataviz-soft-orange-10",
      INTERVIEW_REQUESTED: "bg-dataviz-neon-yellow-10",
      INTERVIEW_RESEND: "bg-dataviz-yolk-yellow-10",
      INTERVIEW_SCHEDULED: "bg-dataviz-moss-green-10",
      INTERVIEW_COMPLETED: "bg-dataviz-leaf-green-10",
      HIRED: "bg-dataviz-moss-green-10",
      REJECTED: "bg-dataviz-cherry-red-10",
    },
  },
});
