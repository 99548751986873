import { flexRender } from "@tanstack/react-table";
import { useListViewBody } from "../list-view-body/use-list-view-body";
import { useEffect } from "react";
import { getCommonPinningStyles } from "../../list-view.utils";
import { cn } from "~/utils/cn";

export const ListViewFooter = () => {
  const { table } = useListViewBody();

  useEffect(() => {
    table.getAllColumns().forEach(column => {
      const { meta } = column.columnDef;

      if (meta?.isPinned && !column.getIsPinned()) {
        column.pin("left");
      }
    });
  }, []);

  return (
    <div className="bg-white">
      {table.getFooterGroups().map(footerGroup => (
        <div key={footerGroup.id} className="flex">
          {footerGroup.headers.map(header => (
            <div
              key={header.id}
              style={{
                ...getCommonPinningStyles(header.column),
              }}
              className={cn(
                header.column.getIsPinned() && "sticky z-30 bg-white",
              )}
            >
              {flexRender(header.column.columnDef.footer, header.getContext())}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
