"use client";

import { months } from "moment";
import { FC, useEffect } from "react";
import {
  EditForm,
  RadioField,
  SelectField,
  TextAreaField,
  TextField,
} from "~/components/jobseeker/EditForm";
import { LocationField } from "~/components/jobseeker/EditForm/LocationField";
import { useCreateVolunteerWork } from "~/hooks/jobseeker/useCreateVolunteerWork";
import { useDeleteVolunteerWork } from "~/hooks/jobseeker/useDeleteVolunteerWork";
import { useUpdateVolunteerWork } from "~/hooks/jobseeker/useUpdateVolunteerWork";
import useCustomForm from "~/hooks/useCustomForm";
import Add from "~/icons/Add";
import { curricular } from "~/types/jobseeker";
import { formSchema } from "./schema";
import * as form from "./volunter-work.constants";
import { formatFields } from "./volunter-work.utils";

interface VolunteerWorkProps {
  data?: curricular;
  uid?: number;
  onSubmit: () => void;
}

const VolunteerWork: FC<VolunteerWorkProps> = ({ data, onSubmit, uid }) => {
  const { mutateAsync: update } = useUpdateVolunteerWork();
  const { mutateAsync: create } = useCreateVolunteerWork();
  const { mutateAsync: deleteItem, isPending: isDeleting } =
    useDeleteVolunteerWork();
  const {
    register,
    handleSubmit,
    errorMessages,
    setValue,
    control,
    submit,
    isDirty,
    reset,
    handleDelete,
    watch,
    isValid,
    trigger,
  } = useCustomForm({
    update: newData => update({ uid: uid!, data: newData }),
    create: newData =>
      create({
        uid: uid!,
        data: newData,
      }),
    deleteItem: id => deleteItem({ uid: uid!, id }),
    formSchema,
    data: formatFields(data),
    onSubmit,
    onReset: () => {
      setValue("location", "");
    },
  });

  const endMonth = watch(form.FIELD_NAME_END_MONTH);
  const endYear = watch(form.FIELD_NAME_END_YEAR);
  const startMonth = watch(form.FIELD_NAME_START_MONTH);
  const startYear = watch(form.FIELD_NAME_START_YEAR);
  const currentPosition = watch(form.FIELD_NAME_IS_CURRENTLY_WORKING);

  useEffect(() => {
    if (currentPosition) {
      trigger([form.FIELD_NAME_END_MONTH, form.FIELD_NAME_END_YEAR]);
    }
  }, [currentPosition, trigger, endMonth, endYear, startMonth, startYear]);

  return (
    <EditForm
      title="Volunteer work"
      subTitle={data ? "Edit" : "Add new"}
      onSubmit={submit}
      submitHandler={handleSubmit}
      iconTrigger={!data ? <Add /> : undefined}
      isDirty={isDirty}
      reset={reset}
      onDelete={data ? handleDelete : undefined}
      isDeleting={isDeleting}
      isValid={isValid}
      needsValidity={!data}
    >
      <TextField
        label="Company or Institution Name"
        {...register(form.FIELD_NAME_COMPANY_INSTITUTE_NAME)}
        error={errorMessages[form.FIELD_NAME_COMPANY_INSTITUTE_NAME]}
        required
      />
      <RadioField
        control={control}
        label="Are you currently working in this role?"
        {...register(form.FIELD_NAME_IS_CURRENTLY_WORKING)}
        options={[
          { label: "Yes", value: "YES" },
          { label: "No", value: "NO" },
        ]}
        error={errorMessages[form.FIELD_NAME_IS_CURRENTLY_WORKING]}
        required
      />
      <TextField
        label="Job title"
        {...register(form.FIELD_NAME_JOB_TITLE)}
        error={errorMessages[form.FIELD_NAME_JOB_TITLE]}
        required
      />
      <LocationField
        control={control}
        setValue={(name, data) => setValue(name, data.description)}
        label="Location"
        isCityName
        {...register(form.FIELD_NAME_LOCATION)}
        error={errorMessages[form.FIELD_NAME_LOCATION]}
      />
      <div>
        <span className="text-sm font-semibold">Start date*</span>
        <div className="grid grid-cols-[2fr_1fr] gap-4">
          <SelectField
            control={control}
            {...register(form.FIELD_NAME_START_MONTH)}
            options={months().map((label, i) => ({
              label,
              value: String(i),
            }))}
            label=""
            setValue={setValue}
            placeholder="Month"
            searchByLabel
            error={errorMessages[form.FIELD_NAME_START_MONTH]}
          />
          <SelectField
            control={control}
            {...register(form.FIELD_NAME_START_YEAR)}
            options={new Array(200).fill(100).map((_, i) => ({
              label: `${new Date().getFullYear() - i}`,
              value: `${new Date().getFullYear() - i}`,
            }))}
            label=""
            setValue={setValue}
            placeholder="Year"
            error={errorMessages[form.FIELD_NAME_START_YEAR]}
          />
        </div>
      </div>
      {currentPosition === "NO" && (
        <div>
          <span className="text-sm font-semibold">
            End date{currentPosition === "NO" && "*"}
          </span>
          <div className="grid grid-cols-[2fr_1fr] gap-4">
            <SelectField
              control={control}
              {...register(form.FIELD_NAME_END_MONTH)}
              options={months().map((label, i) => ({
                label,
                value: `${i}`,
              }))}
              label=""
              setValue={setValue}
              placeholder="Month"
              searchByLabel
              error={errorMessages[form.FIELD_NAME_END_MONTH]}
            />
            <SelectField
              control={control}
              {...register(form.FIELD_NAME_END_YEAR)}
              options={new Array(200).fill(100).map((_, i) => ({
                label: `${new Date().getFullYear() - i}`,
                value: `${new Date().getFullYear() - i}`,
              }))}
              label=""
              setValue={setValue}
              placeholder="Year"
              error={errorMessages[form.FIELD_NAME_END_YEAR]}
            />
          </div>
        </div>
      )}
      <TextAreaField
        {...register(form.FIELD_NAME_JOB_DESCRIPTION)}
        label="Job Description"
        subText="This description will be visible to the public, including employers and recruiters. Try to make it brief and concise yet informative about what you did in your job, what tasks you completed or were in charge of, and what accomplishments you had. It should be resume length."
        required
        error={errorMessages.job_description}
      />
    </EditForm>
  );
};

export default VolunteerWork;
