import React from "react";
import { Select } from "~/scalis-components/core/select/select";
import { AdvanceCandidateDrawerContext } from "../advance-candidate-drawer-context";
import {
  FIELD_NAME_STAGE_SELECT,
  LABEL_STAGE_SELECT,
} from "./stage-select.constants";
import { StageSelectProps } from "./stage-select.types";

export const StageSelect: React.FC<StageSelectProps> = ({
  onValueChange,
  value,
  error,
  ...props
}) => {
  const {
    setStageVisibility,
    stages,
    stageId: droppedStageId,
  } = React.useContext(AdvanceCandidateDrawerContext);
  const nextStages = (stages || props.stages)?.slice(1);
  const defaultValue =
    droppedStageId?.toString() || nextStages?.[0]?.id?.toString() || "";

  React.useEffect(() => {
    onValueChange?.(FIELD_NAME_STAGE_SELECT, defaultValue);
  }, []);

  React.useEffect(() => {
    const selectedStage = nextStages?.find(
      stage => stage?.id?.toString() === value,
    );
    if (selectedStage?.visibility) {
      setStageVisibility?.(selectedStage?.visibility);
    }
  }, [nextStages, value]);

  return (
    <Select
      label={LABEL_STAGE_SELECT}
      options={
        nextStages?.map(stage => ({
          label: stage.name || "",
          value: stage.id?.toString() || "",
        })) || []
      }
      value={value || defaultValue}
      setValue={(name, value) => {
        if (Array.isArray(value)) {
          return;
        }
        onValueChange(FIELD_NAME_STAGE_SELECT, value);
      }}
      error={error}
      required={true}
    />
  );
};
