import React from "react";
import { Tabs, TabsList } from "~/components/v2/Tabs"; // TODO: move components
import { TabTrigger } from "./components/tab-trigger";
import { TabContent } from "./components/tab-content";
import { tabs } from "./candidate-tab-view.styles";
import {
  CandidateTabViewProps,
  CandidateTabViewTabsData,
} from "./candidate-tab-view.types";
import { getCandidateViewDetails } from "./candidate-tab-view.utils";

export const CandidateTabView: React.FC<CandidateTabViewProps> = ({
  views,
  tabsContentClassName,
}) => {
  const { container, list } = tabs();

  const { tabTriggers, tabContents } = views.reduce<CandidateTabViewTabsData>(
    ({ tabTriggers, tabContents }, view) => {
      const detailedView = getCandidateViewDetails(view);

      return {
        tabTriggers: [
          ...tabTriggers,
          <TabTrigger key={detailedView.id} {...detailedView} />,
        ],
        tabContents: [
          ...tabContents,
          <TabContent
            key={detailedView.id}
            {...detailedView}
            tabsContentClassName={tabsContentClassName}
          />,
        ],
      };
    },
    { tabTriggers: [], tabContents: [] },
  );

  const defaultValue = views[0]?.type ?? "";

  return (
    <Tabs defaultValue={defaultValue} className={container()}>
      <TabsList className={list()}>{tabTriggers}</TabsList>
      {tabContents}
    </Tabs>
  );
};
