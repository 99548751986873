"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";

import useGetCompanyDefaultMessages from "~/hooks/defaultMessages/useGetCompanyDefaultMessages";
import { ScheduleInterviewContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context";
import { AdvanceCandidateContext } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/advance-candidate-context";

import { CalendarField } from "./components/calendar-field";
import { CandidatesField } from "./components/candidates-field";
import { InterviewLengthField } from "./components/interview-length-field";
import { InterviewersField } from "./components/interviewers-field";
import { MessageSection } from "./components/message-section";
import {
  FIELD_NAME_CALENDAR,
  FIELD_NAME_CANDIDATES,
  FIELD_NAME_HAS_DEFAULT_MESSAGE,
  FIELD_NAME_INTERVIEW_LENGHT,
  FORM_FIELDS,
} from "./scheduler-link-form.constants";
import { SchedulerLinkFormSchema } from "./scheduler-link-form.schema";
import {
  SchedulerLinkFormProps,
  SchedulerLinkSchema,
} from "./scheduler-link-form.types";

export const SchedulerLinkForm = ({
  isSubmit,
  candidate,
  onSubmit,
  onErrorSubmit,
}: SchedulerLinkFormProps) => {
  const { companyId } = useContext(AdvanceCandidateContext);
  const { data: defaultMessages } = useGetCompanyDefaultMessages(companyId!);
  const { defaultCalendarId } = useContext(ScheduleInterviewContext);

  const form = useForm<SchedulerLinkSchema>({
    resolver: zodResolver(SchedulerLinkFormSchema),
    defaultValues: {
      [FIELD_NAME_CALENDAR]: String(defaultCalendarId),
      [FIELD_NAME_CANDIDATES]: [{ id: candidate.id }],
      [FIELD_NAME_INTERVIEW_LENGHT]:
        FORM_FIELDS[FIELD_NAME_INTERVIEW_LENGHT].defaultValue,
      [FIELD_NAME_HAS_DEFAULT_MESSAGE]: false,
    },
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (isSubmit) {
      formRef?.current?.requestSubmit();
    }
  }, [isSubmit]);

  return (
    <FormProvider {...form}>
      <form
        ref={formRef}
        onSubmit={form.handleSubmit(onSubmit!, onErrorSubmit)}
        className="flex flex-col gap-4"
      >
        <CalendarField />
        <CandidatesField candidate={candidate} />
        <InterviewersField />
        <InterviewLengthField />
        <MessageSection defaultMessages={defaultMessages} />
      </form>
    </FormProvider>
  );
};
