import { usePathname, useSearchParams } from "next/navigation";
import { ActionOptions } from "./use-search-params-manager.types";

export const useSearchParamsManager = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getParams = () => {
    return new URLSearchParams(searchParams?.toString());
  };

  const navigate = (params: URLSearchParams, options?: ActionOptions) => {
    const urlParams = params ? `?${params}` : "";
    const finalUrl = `${pathname}${urlParams}`;

    if (options?.replace) {
      window.history.replaceState(null, "", finalUrl);
    } else {
      window.history.pushState(null, "", finalUrl);
    }
  };

  const setQueryParam = (
    name: string,
    value: string,
    options?: ActionOptions,
  ) => {
    const params = getParams();
    params.set(name, value);

    navigate(params, options);
  };

  const removeQueryParam = (name: string, options?: ActionOptions) => {
    const params = getParams();
    params.delete(name);

    navigate(params, options);
  };

  const updateQueryParam = (
    name: string,
    value?: string,
    options?: ActionOptions,
  ) => {
    if (!value) return removeQueryParam(name, options);
    return setQueryParam(name, value, options);
  };

  return {
    searchParams,
    setQueryParam,
    removeQueryParam,
    updateQueryParam,
  };
};
