export const STATUS = {
  PROSPECT: { name: "Prospect", key: "PROSPECT" },
  INVITED_TO_APPLY: {
    name: "Invited to Apply",
    key: "INVITED_TO_APPLY",
  },
  INVITE_DECLINED: {
    name: "Invite Declined",
    key: "INVITE_DECLINED",
  },
  INTERVIEW_NOT_SCHEDULED: {
    name: "Interview Not Scheduled",
    key: "INTERVIEW_NOT_SCHEDULED",
  },
  INTERVIEW_REQUESTED: {
    name: "Interview Requested",
    key: "INTERVIEW_REQUESTED",
  },
  INTERVIEW_RESEND: {
    name: "Interview Resend",
    key: "INTERVIEW_RESEND",
  },
  INTERVIEW_SCHEDULED: {
    name: "Interview Scheduled",
    key: "INTERVIEW_SCHEDULED",
  },
  INTERVIEW_COMPLETED: {
    name: "Interview Completed",
    key: "INTERVIEW_COMPLETED",
  },
  HIRED: { name: "Hired", key: "HIRED" },
  REJECTED: { name: "Rejected", key: "REJECTED" },
};
