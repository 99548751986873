"use client";

import React, { useContext } from "react";
import { Pagination } from "~/scalis-components/core/pagination";
import { ListViewContext } from "~/scalis-components/core/list-view/list-view-context";

export const ListViewPagination = () => {
  const { pagination, data, table } = useContext(ListViewContext);

  const onPageChange = (page: number) => {
    table.setPageIndex(page - 1);
  };

  if (data.length < pagination.pageSize) {
    return null;
  }

  return (
    <Pagination
      className="mt-2 p-0"
      rowsPerPageClassName="top-1"
      totalPages={Number(table.getPageCount().toLocaleString())}
      page={pagination.pageIndex + 1}
      rowsPerPage={pagination.pageSize}
      onChangePage={onPageChange}
    />
  );
};
