"use client";

import React, { useMemo } from "react";
import { UploadCandidateDrawerProps } from "./upload-candidate-drawer.types";
import {
  FORM_ID,
  FORM_FIELDS,
  FIELD_NAME_JOB_LISTING,
  FIELD_NAME_FIRST_NAME,
  FIELD_NAME_LAST_NAME,
  FIELD_NAME_LINKEDIN,
  FIELD_NAME_EMAIL,
  FIELD_NAME_LINKEDIN_URL,
  FIELD_NAME_EMAIL_ADDRESS,
  FIELD_NAME_PHONE_NUMBER,
  FIELD_NAME_SOURCE_CATEGORY,
  FIELD_NAME_SOURCE_NAME,
  FIELD_NAME_WHO_GETS_CREDIT,
  FIELD_NAME_NOTES,
  FIELD_NAME_DOCUMENTS,
} from "./upload-candidate-drawer.constants";
import {
  formDefaultValues,
  formSchema,
  FormValues,
} from "./upload-candidate-drawer.schema";
import { Drawer } from "~/scalis-components/core/drawer";
import { useForm } from "react-hook-form";
import { Select } from "~/scalis-components/core";
import { Editor } from "~/scalis-components/core/input/editor/components/editor/editor";
import { Checkbox } from "~/scalis-components/core/checkbox/checkbox";
import { Input } from "~/scalis-components/core/input";
import { FileInput } from "~/scalis-components/core/input/file";
import { cn } from "~/utils/cn";
import {
  CompanyUserStatusEnum,
  SourceCategoryEnum,
  SourceNameEnum,
  useCreateUploadedCandidateMutation,
  useGetCompanyUsersUploadCandidateQuery,
} from "~/bff/graphql/generated/graphql";
import { zodResolver } from "@hookform/resolvers/zod";
import useCandidateUploadFile from "~/hooks/useCandidateUploadFile";
import { PhoneField } from "~/scalis-components/team/registration/phone-field";

export const UploadCandidateDrawer: React.FC<UploadCandidateDrawerProps> = ({
  open,
  setOpen,
  onOpenChange,
  jobListings,
  jobTitle,
}) => {

  const { data: getCompanyUsers, loading: isGetCompanyUsersLoading } =
    useGetCompanyUsersUploadCandidateQuery();

  const [createUploadedCandidate, { loading: createUploadedCandidateLoading }] =
    useCreateUploadedCandidateMutation();

  const form = useForm<FormValues>({
    defaultValues: formDefaultValues,
    resolver: zodResolver(formSchema),
  });

  const { handleUpload, loading: candidateUploadFileLoading } =
    useCandidateUploadFile();

  const jobListingsOptions = useMemo(
    () =>
      jobListings.map(l => ({
        label: l.listingTitle,
        value: String(l.id),
      })),
    [jobListings],
  );

  const whoGetsCreditOptions = useMemo(() => {
    if (!getCompanyUsers?.GetCompanyUsers?.length) return [];
    const filteredCompanyUsers = getCompanyUsers.GetCompanyUsers.filter(
      u => u.companyUserStatus === CompanyUserStatusEnum.Active,
    );
    return filteredCompanyUsers.map(u => ({
      label: `${u.UserInvitation?.ScalisUser?.firstName} ${u.UserInvitation?.ScalisUser?.lastName}`,
      value: String(u.id),
    }));
  }, [getCompanyUsers?.GetCompanyUsers]);

  const jobListing = form.watch(FIELD_NAME_JOB_LISTING);
  const linkedIn = form.watch(FIELD_NAME_LINKEDIN);
  const email = form.watch(FIELD_NAME_EMAIL);
  const sourceCategory = form.watch(FIELD_NAME_SOURCE_CATEGORY);
  const sourceName = form.watch(FIELD_NAME_SOURCE_NAME);
  const whoGetsCredit = form.watch(FIELD_NAME_WHO_GETS_CREDIT);
  const notes = form.watch(FIELD_NAME_NOTES);
  const documents = form.watch(FIELD_NAME_DOCUMENTS);

  const onSubmit = async (formData: FormValues) => {
    let uploadedDocuments: string[] = [];

    if (formData[FIELD_NAME_DOCUMENTS]?.length) {
      uploadedDocuments = (
        await Promise.all(
          formData[FIELD_NAME_DOCUMENTS].map(doc => handleUpload(doc)),
        )
      ).filter((doc): doc is string => doc !== undefined);
    }

    const { data, errors } = await createUploadedCandidate({
      variables: {
        input: {
          jobListingId: Number(formData[FIELD_NAME_JOB_LISTING]),
          firstName: formData[FIELD_NAME_FIRST_NAME],
          lastName: formData[FIELD_NAME_LAST_NAME],
          emailAddress: formData[FIELD_NAME_EMAIL]
            ? formData[FIELD_NAME_EMAIL_ADDRESS]
            : null,
          linkedinProfile: formData[FIELD_NAME_LINKEDIN]
            ? formData[FIELD_NAME_LINKEDIN_URL]
            : null,
          phoneNumber: formData[FIELD_NAME_PHONE_NUMBER],
          sourceCategory: formData[
            FIELD_NAME_SOURCE_CATEGORY
          ] as SourceCategoryEnum,
          sourceName: formData[FIELD_NAME_SOURCE_NAME] as SourceNameEnum,
          creditCompanyUserId: Number(formData[FIELD_NAME_WHO_GETS_CREDIT]),
          notes: formData[FIELD_NAME_NOTES],
          documents: uploadedDocuments.length ? uploadedDocuments : null,
        },
      },
    });

    if (errors || !data) {
      throw new Error("ERROR_MESSAGE_UPSERT_SKILLS_AND_PREFERENCES");
    }

    setOpen(null);
  };

  const isLoading =
    isGetCompanyUsersLoading ||
    candidateUploadFileLoading ||
    createUploadedCandidateLoading;

  return (
    <Drawer
      isOpen={open}
      onOpenChange={onOpenChange}
      title="Upload Candidate"
      subtitle={jobTitle}
      endAlignedFooterActions
      successButtonProps={{
        form: FORM_ID,
        label: "Confirm",
        type: "submit",
        loading: isLoading,
      }}
      cancelButtonProps={{
        label: "Cancel",
        loading: isLoading,
        onClick: () => setOpen(null),
      }}
    >
      <div className="flex flex-col gap-4">
        <form
          id={FORM_ID}
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-6"
        >
          <div className="flex flex-col gap-2">
            <h2 className=" text-[16px] font-medium leading-6 tracking-[-0.6px] text-[#1F2124]">
              Application Information
            </h2>

            <Select
              {...FORM_FIELDS[FIELD_NAME_JOB_LISTING]}
              error={
                form.formState.errors[FIELD_NAME_JOB_LISTING]?.message as string
              }
              setValue={(name, value) =>
                form.setValue(name as keyof FormValues, value as string, {
                  shouldValidate: true,
                })
              }
              value={jobListing}
              options={jobListingsOptions}
            />
          </div>

          <div className="flex flex-col gap-2">
            <h2 className=" text-[16px] font-medium leading-6 tracking-[-0.6px] text-[#1F2124]">
              Profile Information
            </h2>

            <Input
              {...FORM_FIELDS[FIELD_NAME_FIRST_NAME]}
              {...form.register(FIELD_NAME_FIRST_NAME)}
              error={
                form.formState.errors[FIELD_NAME_FIRST_NAME]?.message as string
              }
            />

            <Input
              {...FORM_FIELDS[FIELD_NAME_LAST_NAME]}
              {...form.register(FIELD_NAME_LAST_NAME)}
              error={
                form.formState.errors[FIELD_NAME_LAST_NAME]?.message as string
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <h3>Candidate Contact</h3>

            <div className="flex gap-4">
              <div
                className={cn(
                  "w-full rounded-xl border border-[#E5E7EB] p-2",
                  linkedIn && "bg-neutral-20",
                  !linkedIn &&
                    !email &&
                    form.formState.isSubmitted &&
                    "border-error-primary",
                )}
              >
                <Checkbox
                  {...FORM_FIELDS[FIELD_NAME_LINKEDIN]}
                  checked={linkedIn}
                  onCheckedChange={e => {
                    form.setValue(FIELD_NAME_LINKEDIN, e as boolean, {
                      shouldValidate: true,
                    });
                  }}
                />
              </div>
              <div
                className={cn(
                  "w-full rounded-xl border border-[#E5E7EB] p-2",
                  email && "bg-neutral-20",
                  !linkedIn &&
                    !email &&
                    form.formState.isSubmitted &&
                    "border-error-primary",
                )}
              >
                <Checkbox
                  {...FORM_FIELDS[FIELD_NAME_EMAIL]}
                  checked={email}
                  onCheckedChange={e => {
                    form.setValue(FIELD_NAME_EMAIL, e as boolean, {
                      shouldValidate: true,
                    });
                  }}
                />
              </div>
            </div>
            {form.formState.errors.contactMethod?.message && (
              <p className="text-xs text-error">
                {String(form.formState.errors.contactMethod?.message)}
              </p>
            )}
            <p className=" text-[12px] font-normal leading-4 tracking-[-0.3px] text-[#606E7F]">
              At least one is required.
            </p>

            <div className="flex flex-col gap-4">
              {Boolean(linkedIn) && (
                <Input
                  {...FORM_FIELDS[FIELD_NAME_LINKEDIN_URL]}
                  {...form.register(FIELD_NAME_LINKEDIN_URL)}
                  error={
                    form.formState.errors[FIELD_NAME_LINKEDIN_URL]
                      ?.message as string
                  }
                  disabled={!linkedIn}
                />
              )}

              {Boolean(email) && (
                <Input
                  {...FORM_FIELDS[FIELD_NAME_EMAIL_ADDRESS]}
                  {...form.register(FIELD_NAME_EMAIL_ADDRESS)}
                  error={
                    form.formState.errors[FIELD_NAME_EMAIL_ADDRESS]
                      ?.message as string
                  }
                  disabled={!email}
                />
              )}
            </div>
          </div>

          <PhoneField
            {...FORM_FIELDS[FIELD_NAME_PHONE_NUMBER]}
            {...form.register(FIELD_NAME_PHONE_NUMBER)}
            error={
              form.formState.errors[FIELD_NAME_PHONE_NUMBER]?.message as string
            }
            control={form.control}
          />

          <div className="flex flex-col gap-4">
            <h2 className=" text-[16px] font-medium leading-6 tracking-[-0.6px] text-[#1F2124]">
              Source Information
            </h2>

            <Select
              {...FORM_FIELDS[FIELD_NAME_SOURCE_CATEGORY]}
              error={
                form.formState.errors[FIELD_NAME_SOURCE_CATEGORY]
                  ?.message as string
              }
              setValue={(name, value) =>
                form.setValue(name as keyof FormValues, value as string, {
                  shouldValidate: true,
                })
              }
              value={sourceCategory}
            />

            <Select
              {...FORM_FIELDS[FIELD_NAME_SOURCE_NAME]}
              error={
                form.formState.errors[FIELD_NAME_SOURCE_NAME]?.message as string
              }
              setValue={(name, value) =>
                form.setValue(name as keyof FormValues, value as string, {
                  shouldValidate: true,
                })
              }
              value={sourceName}
            />

            <Select
              {...FORM_FIELDS[FIELD_NAME_WHO_GETS_CREDIT]}
              error={
                form.formState.errors[FIELD_NAME_WHO_GETS_CREDIT]
                  ?.message as string
              }
              setValue={(name, value) =>
                form.setValue(name as keyof FormValues, value as string)
              }
              value={String(whoGetsCredit)}
              options={whoGetsCreditOptions}
            />
          </div>

          <div>
            <h2 className=" mb-4 text-[16px] font-medium leading-6 tracking-[-0.6px] text-[#1F2124]">
              Notes and Documents
            </h2>
            <h3 className=" text-[14px] font-normal leading-6 tracking-[-0.6px] text-[#1F2124]">
              Note about the candidate (Optional)
            </h3>
            <Editor
              {...FORM_FIELDS[FIELD_NAME_NOTES]}
              value={notes}
              initialContent={notes}
              setValue={(name, value) =>
                form.setValue(name as keyof FormValues, value)
              }
              error={form.formState.errors[FIELD_NAME_NOTES]?.message}
              className="h-[240px]"
            />
            <div className="mt-4">
              <div className="flex flex-col gap-4">
                <div className="rounded-xl bg-[#E7F3FF]">
                  <label
                    htmlFor="fileInput"
                    className="flex w-full cursor-pointer items-center justify-center gap-2 py-2 text-[#0040FF]"
                  >
                    <span className=" text-center text-[14px] font-medium leading-6 tracking-[-0.6px] text-[#0021F8]">
                      Upload Resume or Documents
                    </span>
                    <i className="fa-regular fa-upload size-4" />
                  </label>
                </div>

                <FileInput
                  name={FIELD_NAME_DOCUMENTS}
                  isMultiple
                  onFileChange={(files: FileList) => {
                    form.setValue(FIELD_NAME_DOCUMENTS, Array.from(files));
                  }}
                >
                  <div className="flex flex-col gap-2">
                    {documents?.map((file: File, index: number) => (
                      <div
                        key={`${file.name}-${index}`}
                        className="flex items-center justify-between rounded-lg border border-[#E5E7EB] p-3"
                      >
                        <span className=" text-[14px] font-normal">
                          {file.name}
                        </span>
                        <button
                          onClick={() => {
                            const currentFiles = documents || [];
                            const newFiles = currentFiles.filter(
                              (_: File, i: number) => i !== index,
                            );
                            form.setValue(FIELD_NAME_DOCUMENTS, newFiles);
                          }}
                          className="text-[#606E7F]"
                        >
                          <i className="fa-regular fa-xmark size-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </FileInput>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Drawer>
  );
};
