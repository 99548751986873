import { ApplicationTypeEnum, NameEnum } from "~/bff/graphql/generated/graphql";
import { PopUpType } from "~/scalis-components/pipeline/pipeline.types";
import { useAdvanceCandidatePopUps } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/advance-candidate-context";
import { useActions } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/useActions";
import { ParsedJobListings } from "~/src/app/company/(dashboard)/(applications)/pipeline/hooks/use-requisition-pipeline-data/use-requisition-pipeline-data.types";

const useAdvanceCandidate = () => {
  const { dispatch, setStage } = useActions();
  const {
    setApplicationId,
    setApplicationType,
    setCandidateName,
    setCompanyId,
    setStageId,
    onOpenChange,
    setJobNumber,
    setJobTitle,
    setAlreadyInvitedToJob,
    setRecentlyInvitedToAnotherJob,
    setJobSeekerId,
    setUploadedCandidateId,
    setJobListings,
  } = useAdvanceCandidatePopUps();

  const advance =
    ({
      applicationId,
      applicationType,
      candidateName,
      companyId,
      stageId,
      jobNumber,
      jobTitle,
      milestoneType,
      alreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
      actionType = "advance",
      isFromTalentPool,
      jobSeekerId,
      uploadedCandidateId,
      isFromUpload,
      jobListings,
    }: {
      applicationId?: number;
      applicationType?: ApplicationTypeEnum;
      candidateName?: string;
      companyId: number;
      stageId?: number;
      jobNumber?: number;
      jobTitle?: string;
      milestoneType?: NameEnum | null;
      alreadyInvitedToJob?: boolean;
      recentlyInvitedToAnotherJob?: boolean;
      actionType?: "advance" | "set";
      isFromTalentPool?: boolean;
      jobSeekerId?: number;
      uploadedCandidateId?: number;
      isFromUpload?: boolean;
      jobListings?: ParsedJobListings[];
    }) =>
    () => {
      setApplicationId(applicationId ?? 0);
      setApplicationType?.(applicationType!);
      setCandidateName(candidateName ?? "");
      setCompanyId(companyId);
      setJobSeekerId?.(jobSeekerId ?? 0);
      setUploadedCandidateId?.(uploadedCandidateId ?? 0);
      setStageId(stageId ?? 0);
      setJobNumber(jobNumber);
      setJobTitle(jobTitle);
      setAlreadyInvitedToJob(!!alreadyInvitedToJob);
      setRecentlyInvitedToAnotherJob(!!recentlyInvitedToAnotherJob);
      setJobListings(jobListings ?? []);

      const isStatic =
        !jobSeekerId && applicationType === ApplicationTypeEnum.Static;

      const isFromSourcing = milestoneType === NameEnum.Sourcing;
      const isFromProcessing = milestoneType !== NameEnum.Sourcing;

      if (isFromTalentPool) {
        return onOpenChange(PopUpType.MATCH_CANDIDATE_DRAWER);
      }
      if (isFromUpload) {
        return onOpenChange(PopUpType.UPLOAD_CANDIDATE_DRAWER);
      }
      if (isFromSourcing) {
        if ((alreadyInvitedToJob || recentlyInvitedToAnotherJob) && !isStatic) {
          return onOpenChange(
            alreadyInvitedToJob
              ? PopUpType.ALREADY_INVITED_TO_SAME_JOB_MODAL
              : PopUpType.ALREADY_INVITED_TO_ANOTHER_JOB_MODAL,
          );
        }
        return onOpenChange(
          isStatic
            ? PopUpType.INVITE_UPLOADED_CANDIDATE_DRAWER
            : PopUpType.INVITE_SCALIS_CANDIDATE_DRAWER,
        );
      }
      if (isFromProcessing) {
        return onOpenChange(PopUpType.ADVANCE_CANDIDATE_DRAWER);
      }
      if (actionType === "set") {
        return setStage(applicationId!, stageId!);
      }
      dispatch({ action: "advance", candidateId: applicationId! });
    };

  return advance;
};

export default useAdvanceCandidate;
