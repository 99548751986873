import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip"; // TODO: move components
import { Icon } from "~/scalis-components/core/icon";
import { CopyableCellProps } from "./copyable-cell.types";

export const CopyableCell: React.FC<CopyableCellProps> = ({ value }) => {
  return (
    <div className="group relative flex w-full items-center justify-between">
      <div className="overflow-hidden text-ellipsis text-sm text-typography-high-contrast">
        {value}
      </div>

      <div className="invisible absolute right-0 cursor-pointer rounded-full p-0.5 active:bg-background-pressed group-hover:visible group-hover:static">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <button type="button" onClick={() => navigator.clipboard.writeText(value)}>
                <Icon
                  icon="fa-regular fa-copy"
                  className="hover:bg-surface-brand-hover active:bg-button-brand-primary-light-pressed"
                />
              </button>
              
            </TooltipTrigger>
            <TooltipContent>
              <p className="z-50">Copy</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};
