"use client";

import { useEffect, useRef, useState } from "react";
import { cn } from "~/utils/cn";
import { Button } from "../Button";
import { SanitizedHtml } from "~/scalis-components/core";

const TruncatedText = ({
  text,
  rowNumber = 2,
  className,
  breakLineButton,
  buttonClassName,
}: {
  text?: string;
  rowNumber?: number;
  className?: string;
  breakLineButton?: boolean;
  buttonClassName?: string;
}) => {
  const [extended, setExtended] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const cloneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!text || !ref.current || !cloneRef.current || truncated) return;

    setTruncated(ref.current.offsetHeight < cloneRef.current.offsetHeight);
    cloneRef.current.remove();
  }, [text]);

  const toggle = () => setExtended(currentState => !currentState);

  return (
    <span className="relative flex flex-col">
      <SanitizedHtml
        ref={ref}
        className={cn(
          "pt-2 text-sm text-typography-high-contrast",
          { "overflow-hidden": !extended },
          className,
        )}
        style={
          !extended
            ? {
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: rowNumber,
              }
            : undefined
        }
        rawHtml={text?.replaceAll("\n", "<br />")}
      />
      <SanitizedHtml
        ref={cloneRef}
        className={cn(
          "invisible pt-2 text-sm text-typography-high-contrast",
          className,
        )}
        rawHtml={text?.replaceAll("\n", "<br />")}
      />
      {truncated && (
        <Button
          variant="truncated_link"
          size="sm"
          onClick={toggle}
          className={cn(
            "bottom-0 right-0 h-4 bg-white px-0 pb-1 text-base font-normal leading-5 text-typography-high-contrast !no-underline",
            {
              absolute: !extended && !breakLineButton,
              relative: extended || breakLineButton,
              "self-end": !breakLineButton,
              "self-start pt-1 font-semibold": breakLineButton,
            },
            buttonClassName,
          )}
        >
          {breakLineButton ? "" : "..."}see {extended ? "less" : "more"}
        </Button>
      )}
    </span>
  );
};

export default TruncatedText;
