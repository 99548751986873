import { cn } from "~/utils/cn";
import { TabsContent } from "~/components/v2/Tabs"; // TODO: move component
import { TabContentProps } from "./tab-content.types";

export const TabContent = ({
  id,
  Component,
  tabsContentClassName,
}: TabContentProps) => {
  return (
    <TabsContent
      key={id}
      value={id}
      className={cn("mt-0 h-[calc(100%-70px)] w-full", tabsContentClassName)}
    >
      {Component}
    </TabsContent>
  );
};
