export const DIALOG_QUERY_PARAM = "editStageCollaborators";

export const DIALOG_TITLE = "Stage Collaborators";

export const DIALOG_INPUT_LABEL = "Invite with email";
export const DIALOG_INPUT_PLACEHOLDER = "Search by name or email";

export const DIALOG_COPY_BUTTON_TITLE = "Copy Stage Link";
export const DIALOG_COPY_SUCCESS_TITLE = "Success!";
export const DIALOG_COPY_SUCCESS_DESCRIPTION = "Stage link copied.";

export const DIALOG_SECTION_TITLE = "Stage Collaborators";
export const DIALOG_MEMBER_TERM = "stage collaborator";
