import { Tag } from "~/scalis-components/core";
import { Body, BodySize, BodyType } from "~/scalis-components/core/typography";
import { SkillTagProps } from "./skill.types";

export const SkillTag = ({
  name,
  level,
  yearsOfExp,
  ...props
}: SkillTagProps) => {
  return (
    <Tag
      {...props}
      text={
        <div className="flex">
          <Body type={BodyType.emphasys}>{name}</Body>
          <Body size={BodySize.small} italic className="text-neutral-secondary">
            ・{level}・{yearsOfExp} years
          </Body>
        </div>
      }
    />
  );
};
