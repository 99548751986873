import React, { useContext, useEffect } from "react";
import { CandidateActionBarProps } from "./candidate-action-bar.types";
import { cn } from "~/utils/cn";
import { ActionButtons } from "./components/ActionButtons";
import { useRejectCandidate } from "~/src/app/company/(dashboard)/(applications)/pipeline/components/reject-candidates";
import { ListViewContext } from "~/scalis-components/core/list-view/list-view-context";

export const CandidateActionBar = ({
  actionsBarItems,
  hideAllActionBars,
}: CandidateActionBarProps) => {
  const { setPreviousSelectCandidates } = useRejectCandidate();

  const { table } = useContext(ListViewContext);
  const { rows } = table.getSelectedRowModel();
  const { single, multiple } = actionsBarItems;

  useEffect(() => {
    if (!!setPreviousSelectCandidates) {
      const selectedCandidates = rows.map(({ id, original }) => ({
        id: Number(id),
        email: original.email,
        name: original.name,
      }));

      setPreviousSelectCandidates(selectedCandidates);
    }
  }, [rows, setPreviousSelectCandidates]);

  if (!rows?.length || hideAllActionBars) return null;

  return (
    <div className="fixed left-1/2 z-40 translate-x-[-50%] animate-action-bar-up">
      <div
        className={cn(
          "flex h-[72px] min-w-[800px] rounded-xl border border-solid border-neutral-30 bg-white shadow-lg",
        )}
      >
        <div className="flex items-center justify-center gap-2 border-r border-solid border-neutral-30 px-6">
          <div className="flex size-5 items-center justify-center rounded-full bg-brand-primary-rest">
            <span className="text-xs font-semibold text-white">
              {rows.length}
            </span>
          </div>
          <p className="text-sm/6 font-normal text-neutral-primary">{`Item${rows.length > 1 ? "s" : ""} selected`}</p>
        </div>
        <div className="flex gap-2 px-6 py-4">
          <ActionButtons
            actionBarItems={rows.length === 1 ? single : multiple}
            candidates={rows.map(({ original }) => original)}
          />
        </div>
      </div>
    </div>
  );
};
