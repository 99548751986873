import { z } from "zod";
import {
  FIELD_NAME_JOB_LISTING,
  FIELD_NAME_FIRST_NAME,
  FIELD_NAME_LAST_NAME,
  FIELD_NAME_LINKEDIN,
  FIELD_NAME_EMAIL,
  FIELD_NAME_LINKEDIN_URL,
  FIELD_NAME_EMAIL_ADDRESS,
  FIELD_NAME_PHONE_NUMBER,
  FIELD_NAME_SOURCE_CATEGORY,
  FIELD_NAME_SOURCE_NAME,
  FIELD_NAME_WHO_GETS_CREDIT,
  FIELD_NAME_NOTES,
  FIELD_NAME_DOCUMENTS,
  FIELD_NAME_CONTACT_METHOD,
} from "./upload-candidate-drawer.constants";

export const formDefaultValues = {
  [FIELD_NAME_JOB_LISTING]: "",
  [FIELD_NAME_FIRST_NAME]: "",
  [FIELD_NAME_LAST_NAME]: "",
  [FIELD_NAME_LINKEDIN]: false,
  [FIELD_NAME_LINKEDIN_URL]: "https://www.linkedin.com/in/",
  [FIELD_NAME_EMAIL]: false,
  [FIELD_NAME_EMAIL_ADDRESS]: "",
  [FIELD_NAME_PHONE_NUMBER]: "",
  [FIELD_NAME_SOURCE_CATEGORY]: "",
  [FIELD_NAME_SOURCE_NAME]: "",
  [FIELD_NAME_WHO_GETS_CREDIT]: "",
  [FIELD_NAME_NOTES]: "",
  [FIELD_NAME_DOCUMENTS]: [],
  [FIELD_NAME_CONTACT_METHOD]: "",
};

export const formSchema = z
  .object({
    [FIELD_NAME_JOB_LISTING]:  z.string({
      required_error: "Please select an option",
      invalid_type_error: "Please select an option",
    })
    .min(1, "Please select an option"),
    [FIELD_NAME_FIRST_NAME]: z
      .string({
        required_error: "Please enter first name",
        invalid_type_error: "Please enter first name",
      })
      .min(1, "Please enter first name"),
    [FIELD_NAME_LAST_NAME]: z
      .string({
        required_error: "Please enter last name",
        invalid_type_error: "Please enter last name",
      })
      .min(1, "Please enter last name"),
    [FIELD_NAME_LINKEDIN]: z.boolean(),
    [FIELD_NAME_EMAIL]: z.boolean(),
    [FIELD_NAME_LINKEDIN_URL]: z.string().optional(),
    [FIELD_NAME_EMAIL_ADDRESS]: z.string().optional(),
    [FIELD_NAME_PHONE_NUMBER]: z.string().optional(),
    [FIELD_NAME_SOURCE_CATEGORY]:  z.string({
      required_error: "Please select an option",
      invalid_type_error: "Please select an option",
    })
    .min(1, "Please select an option"),
    [FIELD_NAME_SOURCE_NAME]:  z.string({
      required_error: "Please select an option",
      invalid_type_error: "Please select an option",
    })
    .min(1, "Please select an option"),
    [FIELD_NAME_WHO_GETS_CREDIT]: z.string().optional(),
    [FIELD_NAME_NOTES]: z.string().optional(),
    [FIELD_NAME_DOCUMENTS]: z
      .array(
        z.instanceof(File),
      )
      .optional(),
    [FIELD_NAME_CONTACT_METHOD]: z.string().optional(),
  })
  .refine(data => data[FIELD_NAME_LINKEDIN] || data[FIELD_NAME_EMAIL], {
    message: "Pealse, select one",
    path: [FIELD_NAME_CONTACT_METHOD],
  })
  .refine(
    data =>
      !data[FIELD_NAME_LINKEDIN] ||
      (data[FIELD_NAME_LINKEDIN] &&
        data[FIELD_NAME_LINKEDIN_URL] &&
        data[FIELD_NAME_LINKEDIN_URL].length >
          formDefaultValues[FIELD_NAME_LINKEDIN_URL].length),
    {
      message: "Please enter a LinkedIn username",
      path: [FIELD_NAME_LINKEDIN_URL],
    },
  )
  .refine(
    data =>
      !data[FIELD_NAME_EMAIL] ||
      (data[FIELD_NAME_EMAIL] &&
        data[FIELD_NAME_EMAIL_ADDRESS] &&
        z.string().email().safeParse(data[FIELD_NAME_EMAIL_ADDRESS]).success),
    {
      message: "Please enter a valid email address",
      path: [FIELD_NAME_EMAIL_ADDRESS],
    },
  );

export type FormValues = z.infer<typeof formSchema>;
