import { FC } from "react";
import { Controller } from "react-hook-form";
import {
  EditForm,
  NumberField,
  SelectField,
  TextField,
} from "~/components/jobseeker/EditForm";
import { Button } from "~/components/v2/Button";
import { useCreateSkill } from "~/hooks/jobseeker/useCreateSkill";
import { useDeleteSkill } from "~/hooks/jobseeker/useDeleteSkill";
import { useUpdateSkill } from "~/hooks/jobseeker/useUpdateSkill";
import useCustomForm from "~/hooks/useCustomForm";
import { skill } from "~/types/jobseeker";
import { formSchema } from "./schema";

interface SkillProps {
  data?: skill;
  uid?: number;
  onSubmit: () => void;
}

const levels = ["Beginner", "Intermediate", "Advanced", "Expert"];

const Skill: FC<SkillProps> = ({ data, onSubmit, uid }) => {
  const { mutateAsync: update } = useUpdateSkill();
  const { mutateAsync: create } = useCreateSkill();
  const { mutateAsync: deleteItem, isPending: isDeleting } = useDeleteSkill();
  const {
    register,
    handleSubmit,
    errorMessages,
    control,
    submit,
    isDirty,
    handleDelete,
    reset,
    setValue,
    isValid,
  } = useCustomForm({
    update: newData => update({ uid: uid!, data: newData }),
    create: newData =>
      create({
        uid: uid!,
        data: newData,
      }),
    deleteItem: id => deleteItem({ uid: uid!, id }),
    formSchema,
    data,
    onSubmit,
    onReset: () => {
      setValue("skill_level", "");
      setValue("skill_name", "");
      setValue("year_of_experience", "");
    },
  });

  return (
    <EditForm
      title="Skill or expertise"
      subTitle={data ? "Edit" : "Add new"}
      onSubmit={submit}
      submitHandler={handleSubmit}
      fullSizeTrigger={!data}
      iconTrigger={
        !data ? (
          <Button
            type="button"
            variant="outline"
            className="h-10 w-full rounded-xl border-primary-1-90 text-primary-1-90"
          >
            Add new
          </Button>
        ) : undefined
      }
      isDirty={isDirty}
      reset={reset}
      onDelete={data ? handleDelete : undefined}
      isDeleting={isDeleting}
      isValid={isValid}
      needsValidity={!!data}
    >
      <TextField
        label="Skill or Expertise Name"
        {...register("skill_name")}
        error={errorMessages.skill_name}
        required
      />
      <Controller
        name="year_of_experience"
        control={control}
        render={({ field: { onChange, name, value, onBlur } }) => (
          <NumberField
            name={name}
            value={value}
            onValueChange={values =>
              onChange({ target: { name, value: values.floatValue || 0 } })
            }
            label="Years of Experience"
            error={errorMessages.year_of_experience}
            required
            onBlur={onBlur}
          />
        )}
      />
      <SelectField
        {...register("skill_level")}
        label="Skill Level"
        error={errorMessages.skill_level}
        options={levels.map(label => ({ value: label.toUpperCase(), label }))}
        setValue={setValue}
        control={control}
        required
      />
    </EditForm>
  );
};

export default Skill;
