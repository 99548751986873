import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { TemplateMessage } from "~/bff/graphql/generated/graphql";
import {
  EmployerMessage,
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  NotificationRadio,
} from "~/scalis-components/pipeline/candidate-drawer";
import {
  LABEL_NOTIFICATION_RADIO,
  NOTIFICATION_RADIO_OPTIONS,
} from "../../advance-candidate-drawer.constants";
import { AdvanceCandidateDrawerContext } from "../advance-candidate-drawer-context";
import { InterviewerSelectionContainer } from "../interviewer-selection/components";
import { FIELD_NAME_INTERVIEWER_SELECTION } from "../interviewer-selection/interviewer-selection.constants";
import { ScheduleRadio } from "../schedule-radio";
import { StageSelect } from "../stage-select";
import { FIELD_NAME_STAGE_SELECT } from "../stage-select/stage-select.constants";
import {
  AdvanceCandidateFormProps,
  FormValues,
  createSchema,
} from "./advance-candidate-form.types";

export const AdvanceCandidateForm: React.FC<AdvanceCandidateFormProps> = ({
  isSubmit,
  onErrorSubmit,
  onSubmit,
}) => {
  const {
    isStaticProfile,
    showInterviewerSelection,
    isPublicStage,
    isNotifyCandidate,
    setIsNotifyCandidate,
    defaultMessages,
  } = React.useContext(AdvanceCandidateDrawerContext);
  const mustNotifyCandidate = isPublicStage && !isStaticProfile;
  const shouldNotifyCandidate = isPublicStage && isNotifyCandidate;
  const schema = createSchema(mustNotifyCandidate, shouldNotifyCandidate);
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });
  const formRef = React.useRef<HTMLFormElement | null>(null);
  React.useEffect(() => {
    if (isSubmit) {
      formRef?.current?.requestSubmit();
    }
  }, [isSubmit]);

  return (
    <form
      ref={formRef}
      className="flex flex-col overflow-auto"
      onSubmit={handleSubmit(onSubmit!, onErrorSubmit)}
    >
      <div className="flex flex-col gap-6 px-6 py-4">
        <div>
          <StageSelect
            error={errors[FIELD_NAME_STAGE_SELECT]?.message}
            onValueChange={setValue}
            value={watch(FIELD_NAME_STAGE_SELECT)!}
          />
        </div>
        {isPublicStage && (
          <>
            {isStaticProfile && (
              <div>
                <NotificationRadio
                  label={LABEL_NOTIFICATION_RADIO}
                  onValueChange={(name: keyof FieldValues, value: string) => {
                    setValue(name as keyof FormValues, value);
                    setIsNotifyCandidate?.(!!parseInt(value));
                  }}
                  notificationOptions={NOTIFICATION_RADIO_OPTIONS}
                  value={NOTIFICATION_RADIO_OPTIONS[1].value}
                />
              </div>
            )}
            {isNotifyCandidate && (
              <>
                <div>
                  <EmployerMessage
                    defaultMessages={
                      defaultMessages as unknown as TemplateMessage[]
                    }
                    onValueChange={setValue}
                    triggerValidation={trigger}
                    customMessage={watch(FIELD_NAME_CUSTOM_MESSAGE)}
                    defaultMessage={watch(FIELD_NAME_DEFAULT_MESSAGE)}
                    defaultMessageError={
                      errors[FIELD_NAME_DEFAULT_MESSAGE]?.message
                    }
                    customMessageError={
                      errors[FIELD_NAME_CUSTOM_MESSAGE]?.message
                    }
                  />
                </div>
                <div>
                  <ScheduleRadio onValueChange={setValue} />
                </div>
                {showInterviewerSelection && (
                  <div>
                    <InterviewerSelectionContainer
                      error={errors[FIELD_NAME_INTERVIEWER_SELECTION]?.message}
                      onValueChange={setValue}
                      values={watch(FIELD_NAME_INTERVIEWER_SELECTION)}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </form>
  );
};
