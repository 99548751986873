"use client";

import { useContext } from "react";
import { useGlobalListView } from "../context/global-list-view-context";
import { ListViewContext } from "../list-view-context";

interface ListViewContainerProps {
  children: React.ReactNode;
}

export const ListViewContainer = ({ children }: ListViewContainerProps) => {
  const { table, id } = useContext(ListViewContext);

  useGlobalListView({
    id,
    selectedItems: table?.getSelectedRowModel().rows.length,
  });

  return (
    <div className="flex-1 overflow-auto pb-4 [&::-webkit-scrollbar-thumb]:bg-background-muted [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-track]:shadow-none">
      <div
        style={{ minWidth: table.getCenterTotalSize() }}
        className="grid-cols-auto grid min-w-full"
      >
        {children}
      </div>
    </div>
  );
};
