import React from "react";
import { StatusProps } from "./status.types";
import { formatTime } from "./status.utils";
import { STATUS } from "./status.constants";
import { statusStyles } from "./status.styles";
import { Tag } from "~/scalis-components/core/tag";

export const Status: React.FC<StatusProps> = ({
  status,
  lastUpdatedStatusAt,
}: StatusProps) => {
  const statusName = STATUS[status as keyof typeof STATUS]?.name;
  const statusKey = STATUS[status as keyof typeof STATUS]?.key;
  const formattedTime = formatTime(lastUpdatedStatusAt);

  return (
    <Tag
      className={statusStyles({ type: statusKey as keyof typeof STATUS })}
      small
      text={
        <div className="flex items-center justify-center gap-1.5">
          <span className="line-clamp-1 text-xs font-medium">{statusName}</span>
          {formattedTime && (
            <span className="text-sm font-normal italic">{formattedTime}</span>
          )}
        </div>
      }
    />
  );
};
