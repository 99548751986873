import { useQuery } from "@tanstack/react-query";
import {
  Response,
  fetchApplicationsByApplicant,
} from "~/queries/applications/fetchApplicationsByApplicant";
import { FetchByJobSeekerPaginatedOptions } from "~/services/jobSeekerApplications.service";

export const useGetApplicationsByApplicants = (
  type: string,
  id?: number | null,
  queryParams?: FetchByJobSeekerPaginatedOptions,
  initialData?: Response,
) => {
  return useQuery({
    queryKey: [
      "applications-by-applicant",
      type,
      queryParams?.sortBy,
      queryParams?.search,
    ],
    queryFn: params => fetchApplicationsByApplicant(params, id, queryParams),
    enabled: !!id,
    initialData,
  });
};
