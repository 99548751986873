import { matchScore } from "./match-score.styles";
import { MatchScoreProps } from "./match-score.types";
import { getMatchScoreBgColor, getRoundMatchScore } from "./match-score.utils";

export const MatchScore: React.FC<MatchScoreProps> = ({ score }) => {
  const color = getMatchScoreBgColor(
    score,
  ) as keyof typeof matchScore.variants.color;
  const roundMatchScore = getRoundMatchScore(score);
  return (
    <div className={matchScore({ color })}>
      <span className="text-sm/6 font-medium text-neutral-primary">
        {roundMatchScore}
      </span>
    </div>
  );
};
