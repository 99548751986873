import { FC, type JSX } from "react";
import SocialMediaForm from "~/components/forms/v2/jobseeker/SocialMedia";
import Facebook from "~/icons/Facebook";
import Globe from "~/icons/Globe";
import LinkedIn from "~/icons/LinkedIn";
import XTwitter from "~/icons/XTwitter";
import { jobSeekerBasicInfo } from "~/types/jobseeker";
import { Avatar, AvatarFallback } from "../v2/Avatar";
import { Card, CardTitle } from "../v2/Card";
import { Skeleton } from "../v2/Skeleton";
import { DefaultProps } from "./DefaultProps";

const socialMedias = (data: jobSeekerBasicInfo) => [
  {
    label: `${data.f_name} ${data.l_name}'s LinkedIn profile`,
    href: data.linkedin_link,
    icon: LinkedIn,
  },
  {
    label: `${data.f_name} ${data.l_name}'s Twitter profile`,
    href: data.twitter_link,
    icon: XTwitter,
  },
  {
    label: `${data.f_name} ${data.l_name}'s Facebook profile`,
    href: data.facebook_link,
    icon: Facebook,
  },
  {
    label: `${data.f_name} ${data.l_name}'s Website Link 1`,
    href: data.website_link,
    icon: Globe,
  },
  {
    label: `${data.f_name} ${data.l_name}'s Website Link 2`,
    href: data.website_b_link,
    icon: Globe,
  },
];

const SocialMedia: FC<DefaultProps> = ({
  data,
  loading,
  refetch,
  isEdition,
}) => {
  return (
    <Card className="gap-2 p-4">
      <CardTitle className="text-base">
        Websites and Social Media
        {isEdition && (
          <SocialMediaForm
            data={data?.job_seeker_basicinfo}
            onSubmit={refetch}
          />
        )}
      </CardTitle>
      {loading ? (
        <Loading />
      ) : (
        data?.job_seeker_basicinfo &&
        socialMedias(data?.job_seeker_basicinfo).map(
          item => item.href && <SocialMediaItem key={item.label} {...item} />,
        )
      )}
    </Card>
  );
};

const Loading = () => (
  <>
    {new Array(3).fill(1).map((_, i) => (
      <div key={i} className="flex items-center gap-2">
        <Avatar className="h-8 w-8">
          <AvatarFallback>
            <Skeleton className="h-full w-full"></Skeleton>
          </AvatarFallback>
        </Avatar>
        <Skeleton className="h-3 w-52"></Skeleton>
      </div>
    ))}
  </>
);

interface SocialMediaItemProps {
  label: string;
  href?: string;
  icon: () => JSX.Element;
}

const SocialMediaItem: FC<SocialMediaItemProps> = ({
  label,
  href,
  icon: Icon,
}) => {
  return (
    <a
      href={href}
      target="_blank"
      className="flex items-center gap-2 text-sm font-semibold text-[#000D3D]"
      rel="noreferrer noopener"
    >
      <Avatar className="h-8 w-8">
        <AvatarFallback className="bg-[#C7E4F9] text-[#001855]">
          <Icon />
        </AvatarFallback>
      </Avatar>
      {label}
    </a>
  );
};

export default SocialMedia;
