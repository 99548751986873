import { EmptyState } from "~/scalis-components/core";
import { ErrorStateProps } from "./error-state.types";

export const ErrorState = (props: ErrorStateProps) => {
  return (
    <EmptyState
      title="Something went wrong!"
      message="Please reload the page."
      icon="fa-regular fa-triangle-exclamation"
      iconSize="fa-3x"
      {...props}
    />
  );
};
