import {
  NameEnum,
  TemplateStageVisibilityEnum,
} from "~/bff/graphql/generated/graphql";
import { CandidateApplicationFilterTab } from "../team/contexts/candidate-application-filters-context";

export const APPLICATION_TYPE = {
  DYNAMIC: "DYNAMIC",
  STATIC: "STATIC",
} as const;

export const SOURCES = {
  SOURCED: { name: "Sourced", value: "SOURCED" },
  DIRECT_APPLICANT: { name: "Direct Applicant", value: "DIRECT_APPLICANT" },
  AGENCY: { name: "Agency", value: "AGENCY" },
  REFERRED: { name: "Referred", value: "REFERRED" },
  UNIVERSITY: { name: "University", value: "UNIVERSITY" },
  INTERNAL: { name: "Internal", value: "INTERNAL" },
};

export const MILESTONE_TYPE = {
  SOURCING: "Sourcing",
  APPLICATION_REVIEW: "Application Review",
  PRE_SCREEN: "Pre-Screen",
  CUSTOM: "Custom",
  OFFER: "Offer",
  HIRED: "Hired",
};

export const VISIBILITY: Record<TemplateStageVisibilityEnum, string> = {
  [TemplateStageVisibilityEnum.VisibleOnlyInternally]: "Internal",
  [TemplateStageVisibilityEnum.VisibleToCandidate]: "Public",
};

export const MILESTONE_BG_DEFAULT = "bg-[#E5C9FB]";

type MilestoneColorMap = Record<NameEnum, string>;

export const MILESTONE_BG: MilestoneColorMap = {
  SOURCING: "bg-[#EFE5FF]",
  APPLICATION_REVIEW: "bg-[#E5EBFF]",
  PRE_SCREEN: "bg-[#CCF0FF]",
  INTERVIEWING: "bg-[#CCFFFE]",
  OFFER: "bg-[#CCFFDA]",
  HIRED: "bg-[#EAFFCC]",
};

export const MILESTONE_HEADER_BG: MilestoneColorMap = {
  SOURCING: "bg-[#E0CCFF]",
  APPLICATION_REVIEW: "bg-[#C9D5FB]",
  PRE_SCREEN: "bg-[#B2E8FF]",
  INTERVIEWING: "bg-[#ACFAF9]",
  OFFER: "bg-[#A6FFC0]",
  HIRED: "bg-[#D7FF9E]",
};

export const MILESTONE_TITLE_BG: MilestoneColorMap = {
  SOURCING: "bg-[#D0B3FF]",
  APPLICATION_REVIEW: "bg-[#A5BAFC]",
  PRE_SCREEN: "bg-[#7DD5FB]",
  INTERVIEWING: "bg-[#81F4F2]",
  OFFER: "bg-[#6DF694]",
  HIRED: "bg-[#B3F05D]",
};

export const MILESTONE_SUBTITLE_BG: MilestoneColorMap = {
  SOURCING: "bg-[#EFE5FF]",
  APPLICATION_REVIEW: "bg-[#E5EBFF]",
  PRE_SCREEN: "bg-[#CCF0FF]",
  INTERVIEWING: "bg-[#CCFFFE]",
  OFFER: "bg-[#CCFFDA]",
  HIRED: "bg-[#EAFFCC]",
};

export const PIPELINE_FILTER_TABS = [
  {
    label: "Active Candidates",
    value: CandidateApplicationFilterTab.ACTIVE_CANDIDATES,
  },
  {
    label: "SCALIS Candidates",
    value: CandidateApplicationFilterTab.SCALIS_CANDIDATES,
  },
  {
    label: "Uploaded",
    value: CandidateApplicationFilterTab.UPLOADED,
  },
  {
    label: "Invited to Apply",
    value: CandidateApplicationFilterTab.INVITED_TO_APPLY,
  },
  {
    label: "Rejected",
    value: CandidateApplicationFilterTab.REJECTED,
  },
];
