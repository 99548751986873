import { z } from "zod";
import { validateNumber } from "~/utils/validateNumber";
import * as form from "./volunter-work.constants";
import { isNumber } from "lodash";

const NOT_WORKING = "NO";

export const formSchema = z
  .object({
    [form.FIELD_NAME_COMPANY_INSTITUTE_NAME]: z
      .string()
      .nonempty("Please enter company or institution name"),
    [form.FIELD_NAME_JOB_DESCRIPTION]: z
      .string()
      .nonempty("Please enter job description"),
    [form.FIELD_NAME_JOB_TITLE]: z.string().nonempty("Please enter job title"),
    [form.FIELD_NAME_LOCATION]: z.string().optional().nullable(),
    [form.FIELD_NAME_IS_CURRENTLY_WORKING]: z
      .string()
      .nonempty("Required.")
      .pipe(z.enum(["YES", "NO"])),
    [form.FIELD_NAME_START_YEAR]: z.coerce
      .number()
      .refine(value => value != null, {
        message: "Please select a starting year",
      }),
    [form.FIELD_NAME_END_YEAR]: z
      .preprocess(validateNumber, z.number().optional())
      .optional(),
    [form.FIELD_NAME_START_MONTH]: z.coerce
      .number()
      .refine(value => value != null, {
        message: "Please select a starting month",
      }),
    [form.FIELD_NAME_END_MONTH]: z
      .preprocess(validateNumber, z.number().optional())
      .optional(),
  })
  .superRefine((values: any, context) => {
    if (
      values[form.FIELD_NAME_IS_CURRENTLY_WORKING] === NOT_WORKING &&
      !isNumber(values[form.FIELD_NAME_END_MONTH])
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter ending month",
        path: [form.FIELD_NAME_END_MONTH],
      });
    }

    if (
      values[form.FIELD_NAME_IS_CURRENTLY_WORKING] === NOT_WORKING &&
      !isNumber(values[form.FIELD_NAME_END_YEAR])
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please enter ending year",
        path: [form.FIELD_NAME_END_YEAR],
      });
    }

    if (
      values[form.FIELD_NAME_IS_CURRENTLY_WORKING] === NOT_WORKING &&
      isNumber(values[form.FIELD_NAME_END_YEAR]) &&
      values[form.FIELD_NAME_END_YEAR] < values[form.FIELD_NAME_START_YEAR]
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must be greater than starting year",
        path: [form.FIELD_NAME_END_YEAR],
      });
    }

    const isEndMonthPreviousThanStartDate =
      values[form.FIELD_NAME_IS_CURRENTLY_WORKING] === NOT_WORKING &&
      isNumber(values[form.FIELD_NAME_END_YEAR]) &&
      values[form.FIELD_NAME_END_YEAR] === values[form.FIELD_NAME_START_YEAR] &&
      isNumber(values[form.FIELD_NAME_END_MONTH]) &&
      values[form.FIELD_NAME_END_MONTH] < values[form.FIELD_NAME_START_MONTH];

    if (isEndMonthPreviousThanStartDate) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Ending month must be greater than starting date",
        path: ["end_month"],
      });
    }
  });
