import React, { useMemo } from "react";
import Link from "next/link";
import { cn } from "~/utils/cn";
import { ItemContentProps } from "./item-content.props";

export const ItemContent = ({ item }: ItemContentProps) => {
  const content = useMemo(
    () => (
      <>
        {item.icon && (
          <div className="flex size-6 items-center justify-center">
            <i className={cn("text-neutral-primary", item.icon)} />
          </div>
        )}
        <p className="text-sm/6 font-normal tracking-[-0.6px] text-neutral-primary">
          {item.label}
        </p>
      </>
    ),
    [item],
  );

  if (item.href) {
    return (
      <Link className="flex h-9 flex-1 items-center gap-2 p-2" href={item.href}>
        {content}
      </Link>
    );
  }

  return content;
};
